import React, {useState} from 'react';
import css from './check-in.module.css';
import { axiosImageAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL } from '../../../../constants';
import Loader from '../../loader';
import CustomAlertModal from '../../customModal';

const StarRating = ({ rating, setRating }) => {
    const handleRating = (index) => {
        setRating(index + 1);
    }

    return (
        <div className={css.stars}>
            {[...Array(5)].map((star, index) => {
                return (
                    <span
                        key={index}
                        className={`${css.star} ${index < rating ? css.filled : ''}`}
                        onClick={() => handleRating(index)}
                    >
                        ★
                    </span>
                );
            })}
        </div>
    );
};

const NewCheckIn = ({ user }) => {
    const userId = user.id;
    const [weight, setWeight] = useState('');
    const [comment, setComment] = useState('');
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sleepRating, setSleepRating] = useState(3);
    const [nutritionRating, setNutritionRating] = useState(3);
    const [motivationalRating, setMotivationalRating] = useState(3);
    const [trainingRating, setTrainingRating] = useState(3);

    const handleSubmit = async () => {
        setIsLoading(true);

        let formattedWeight = weight.replace(',', '.');
        let weightValue = parseFloat(formattedWeight);

        // Valider at vekten er et gyldig tall
        if (isNaN(weightValue)) {
        alert('Vennligst skriv inn en gyldig vekt');
        setIsLoading(false);
        return;
        }

        // Formatere vekten til én desimal
        weightValue = parseFloat(weightValue.toFixed(1));


        //const formattedWeight = weight.replace(',','.');
        const formData = new FormData();
        formData.append('data', JSON.stringify({
            client: userId,
            weight: weightValue,
            comment,
            rating_training: trainingRating,
            rating_nutrition: nutritionRating,
            rating_motivation: motivationalRating,
            rating_sleep: sleepRating,
            date: new Date(),
        }));

        images.forEach((image, index) => {
            formData.append('files.progress_pic', image);
        });

        try {
            const response = await axiosImageAuthFetch(`${API_BASE_URL}/check-ins`, formData);
            console.log(response);
            alert('Check-in submitted successfully');
            setIsLoading(false);
            setIsModalOpen(false); // Close modal on successful submission
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setIsError(true);
        }
    }

    const handleImageChange = (e) => {
        setImages([...e.target.files]);
    }

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const handleWeightChange = (e) => {
        let value = e.target.value;
        // Erstatt komma med punktum
        value = value.replace(',', '.');
    
        // Tillat kun tall og ett punktum
        if (!/^\d*\.?\d*$/.test(value)) {
          // Ugyldig inndata, ignorer endringen
          return;
        }
        if (value.includes('.')) {
            //eslint-disable-next-line
            const [integerPart, decimalPart] = value.split('.');
            if (decimalPart.length > 1) {
              // Hvis mer enn én desimal, ignorer endringen
              return;
            }
          }
      
          setWeight(value);
        };
    

    if (isLoading) {
        return (<Loader />);
    }
    if (isError) {
        return (
            <div>Oida, her gikk noe galt! Vennligst prøv å last inn siden på nytt!</div>
        );
    }

    return (
        <div className={css.container}>
            <button className={css.submitButton} onClick={openModal}>Ny Check-In</button>
            <CustomAlertModal
                show={isModalOpen}
                handleClose={closeModal}
                content={
                    <form className={css.form}>
                        <div className={css.weightInput}>
                            <label>Vekt (i kg)</label>
                            <input className={css.weightInputfield} type="text" inputMode='decimal' value={weight} onChange={handleWeightChange} required />
                        </div>
                        <div className={css.inputRating}>
                            <label>Søvn</label>
                            <StarRating rating={sleepRating} setRating={setSleepRating} />
                        </div>
                        <div className={css.inputRating}>
                            <label>Trening</label>
                            <StarRating rating={trainingRating} setRating={setTrainingRating} />
                        </div>
                        <div className={css.inputRating}>
                            <label>Kosthold</label>
                            <StarRating rating={nutritionRating} setRating={setNutritionRating} />
                        </div>
                        <div className={css.inputRating}>
                            <label>Motivasjon</label>
                            <StarRating rating={motivationalRating} setRating={setMotivationalRating} />
                        </div>
                        <div className={css.commentInput}>
                            <label>Kommentar</label>
                            <textarea rows="5" className={css.inputInput} type="input" value={comment} onChange={(e) => setComment(e.target.value)} />
                        </div>
                        <div className={css.imageInput}>
                            <label>Last opp bilder:</label>
                            <input type="file" multiple onChange={handleImageChange} />
                        </div>
                        <div className={css.submitButton} onClick={handleSubmit}>SEND INN</div>
                    </form>
                }
                submit={handleSubmit}
                modalTitle="Hvordan har din uke vært?"
            />
        </div>
    );
}

export default NewCheckIn;