import React, { useState, useEffect } from 'react';
import templateCss from './template.module.css';
import { useGetProgramsByCoach } from '../../../../hooks/programs';
import Loader from '../../../global/loader';
import UpdateForm from '../../../global/forms/updateProgram';
import { useExercises } from '../../../../hooks/exercises';
import CustomAlertModal from '../../../global/customModal';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL, API_GRAPHQL } from '../../../../constants';
import { getLocalStorageItem } from '../../../global/storage';
import UserSort from '../../../global/userSort';
import { filterObjects } from '../../../global/searchFunctionality';

export default function TemplatePage() {
  const { programs, isLoading, isError } = useGetProgramsByCoach();
  const exercises = useExercises();
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const userInfo = getLocalStorageItem('limitless-user');
  const coachID = userInfo.user.coachID;
  const [clients, setClients] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersError, setUsersError] = useState(false);
  const [showUserSort, setShowUserSort] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isListView, setIsListView] = useState(false);

  const query = `
    query{
      coaches(filters: {id:{eq:${coachID}}}) {
        data{
          id
          attributes{
            clients{
              data{
                id
                attributes{
                  username
                }
              }
            }
          }
        }
      }
    }
  `;

  useEffect(() => {
    async function getUsers() {
      setUsersLoading(true);
      setUsersError(false);
      try {
        const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
        console.log(response.data.coaches.data[0].attributes.clients.data);
        setClients(response.data.coaches.data[0].attributes.clients.data);
      } catch (error) {
        console.error(error);
        setUsersError(true);
      } finally {
        setUsersLoading(false);
      }
    }
    getUsers();
  }, [query]);

  const handleShowModal = (program) => {
    setSelectedProgram(program);
  };

  const handleCloseModal = () => {
    setSelectedProgram(null);
    setShowDeleteConfirm(false);
    setShowUserSort(false);
  };

  const handleDeleteProgram = async (programId) => {
    try {
      await axiosAuthFetch(`${API_BASE_URL}/programs/${programId}`, {}, 'DELETE');
      // Oppdater programlisten etter sletting
      setSelectedProgram(null);
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading || usersLoading || !programs) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (isError || usersError) {
    return <div>Oida, her har det skjedd noe galt!</div>;
  }

  if (programs) {
    console.log(programs);
    const filteredPrograms = filterObjects(searchText, programs);

    return (
      <div className={`${templateCss.container1} appearing-container`}>
        <p className={templateCss.overskrift}>DINE MALER OG PROGRAM</p>
        <input
          className={templateCss.search}
          type="text"
          placeholder="Søk etter program..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button
            className={templateCss.toggleButton}
            onClick={() => setIsListView(!isListView)}
          >
            {isListView ? 'Vis som bilder' : 'Vis som liste'}
          </button>
          {isListView ? (
          // Listevisning
          <div className={templateCss.programList}>
            {filteredPrograms.map((program) => {
              const { programName, users } = program.attributes;

              return (
                <div
                  key={program.id}
                  className={templateCss.programListItem}
                  onClick={() => handleShowModal(program)}
                >
                  <span className={templateCss.programListItemName}>{programName}</span>
                  <span className={templateCss.programListItemClients}>
                    {users && users.data.length > 0
                      ? `Klienter: ${users.data.length}`
                      : 'Ingen klienter'}
                  </span>
                </div>
              );
            })}
          </div>
        ) : (
          // Gridvisning (nåværende visning)
          <div className={templateCss.accordionContainer}>
            {filteredPrograms.map((program) => {
              const { programName, cover_image } = program.attributes;
              const imageUrl = cover_image.data.attributes.images.data[0].attributes.url;

              return (
                <div
                  className={templateCss.programItem}
                  key={program.id}
                  onClick={() => handleShowModal(program)}
                  style={{ backgroundImage: `url(${imageUrl})` }}
                >
                  <div className={templateCss.text}>{programName}</div>
                </div>
              );
            })}
          </div>
        )}

        {selectedProgram && (
          <CustomAlertModal
            show={true}
            handleClose={handleCloseModal}
            content={
              <ProgramModalContent
                program={selectedProgram}
                exercises={exercises}
                clients={clients}
                handleDeleteProgram={handleDeleteProgram}
                setShowDeleteConfirm={setShowDeleteConfirm}
                showDeleteConfirm={showDeleteConfirm}
                setShowUserSort={setShowUserSort}
                showUserSort={showUserSort}
              />
            }
            buttonLabel="Lukk"
            modalTitle={selectedProgram.attributes.programName}
          />
        )}
      </div>
    );
  }
}

const ProgramModalContent = ({
  program,
  exercises,
  clients,
  handleDeleteProgram,
  setShowDeleteConfirm,
  showDeleteConfirm,
  setShowUserSort,
  showUserSort,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { pairs, users } = program.attributes;

  const userNames =
    users && users.data.length > 0
      ? users.data.map((user) => user.attributes.username).join(', ')
      : null;

  if (isEditing) {
    return (
      <UpdateForm
        program={program}
        options={exercises}
        onCancel={() => setIsEditing(false)}
      />
    );
  }

  return (
    <div className={templateCss.container}>
      <div className={templateCss.clientsContainer}>
        {userNames
          ? `Klienter på dette programmet: ${userNames}`
          : 'Ingen klienter har dette programmet'}
        <div className={templateCss.editClients} onClick={() => setShowUserSort(true)}>
          Legg til klienter
        </div>
      </div>
      <div className={templateCss.accordionCards}>
        {pairs.data.map((pair, index) => {
          const { description, comment, exercise } = pair.attributes;
          return (
            <div className={`${templateCss.openAccordionContainer1}`} key={pair.id}>
              <div
                className={templateCss.accordionCardImageContainer}
                style={{
                  backgroundImage: `url(${exercise.data.attributes.image.data[0].attributes.url})`,
                }}
              ></div>
              <div className={templateCss.accordionContainer2}>
                <p className={templateCss.exName}>{exercise.data.attributes.name}</p>
                <p className={templateCss.setsReps}>Sett: {description}</p>
                <p className={templateCss.comment}>Reps: {comment}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className={templateCss.buttonContainer}>
        <div className={templateCss.editButton} onClick={() => setIsEditing(true)}>
          Rediger program
        </div>
        <div className={templateCss.deleteButton} onClick={() => setShowDeleteConfirm(true)}>
          Slett program
        </div>
      </div>
      {showDeleteConfirm && (
        <CustomAlertModal
          show={true}
          handleClose={() => setShowDeleteConfirm(false)}
          content="Er du sikker på at du vil slette programmet?"
          buttonLabel="Jeg er sikker"
          modalTitle="Slett program"
          submit={() => handleDeleteProgram(program.id)}
        />
      )}
      {showUserSort && (
        <CustomAlertModal
          show={true}
          handleClose={() => setShowUserSort(false)}
          content={
            <UserSort
              selectedUsers={users.data}
              allUsers={clients}
              programId={program.id}
            />
          }
          buttonLabel="Lukk"
          modalTitle="Legg til klienter"
        />
      )}
    </div>
  );
};