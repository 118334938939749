// import React, {useState, useEffect, useRef} from 'react';
// import { axiosAuthFetch } from '../../../../helpers/axios';
// import CustomAccordion from '../../../global/customAccordion';
// import Loader from '../../../global/loader';
// import templateCss from '../template/template.module.css';
// import { getLocalStorageItem } from '../../../global/storage';
// import { API_BASE_URL, API_GRAPHQL } from '../../../../constants';
// import CustomAlertModal from '../../../global/customModal';

// export default function ClientTemplatePage() {
//     const [programs, setPrograms] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [isError, setIsError] = useState(false);
//     const userInfo = getLocalStorageItem('limitless-user');
//     const clientID = userInfo.user.id;
//     const [searchText, setSearchText] = useState('');
//     const [currentProgram, setCurrentProgram] = useState(null);
//     const [workoutData, setWorkoutData] = useState([]);
//     const [showModal, setShowModal] = useState(false);
//     const [elapsedTime, setElapsedTime] = useState(0);
//     const timerRef = useRef(null);

//     const query = `query{
//         programs(filters:{users:{id:{eq:${clientID}}}}) {
//           data{
//             id
//             attributes{
//               programName
//               pairs{
//                 data{
//                   id
//                   attributes{
//                     description
//                     comment
//                     exercise{
//                       data{
//                         id
//                         attributes{
//                           name
//                           description
//                           image{
//                             data{
//                                 id
//                                 attributes{
//                                     url
//                                 }
//                             }
//                           }
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//               cover_image{
//                 data{
//                   id
//                   attributes{
//                     images{
//                       data{
//                         id
//                         attributes{
//                           url
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//             `;

//     useEffect(() => {
//         async function getPrograms() {
//             setIsLoading(true);
//             setIsError(false);
//             try {
//                 const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
//                 console.log(response.data);
//                 setPrograms(response.data.programs.data);
//             } catch (error) {
//                 console.error(error);
//                 setIsError(true);
//             } finally {
//                 setIsLoading(false);
//             }
//         }
//         getPrograms();
//     }, [query]);

//     useEffect(() => {
//         if (showModal) {
//             timerRef.current = setInterval(() => {
//                 setElapsedTime(prev => prev + 1);
//             }, 1000);
//         } else {
//             clearInterval(timerRef.current);
//         }

//         return () => clearInterval(timerRef.current);
//     }, [showModal]);

//     if (isLoading || !programs) {
//         return (<div><Loader /></div>);
//     }

//     if (isError) {
//         return (<div>Oida, her har det skjedd noe galt!</div>);
//     }

//     const openWorkoutModal = (program) => {
//         setCurrentProgram(program);
//         setShowModal(true);
    
//         const workoutPairs = program.attributes.pairs.data.map(pair => {
//             const setsCount = parseInt(pair.attributes.description, 10);
//             const targetReps = pair.attributes.comment.split('/').map(rep => rep.trim());
    
//             console.log("targetReps:", targetReps); // Logging targetReps for debugging
    
//             return {
//                 pairId: pair.id,
//                 exerciseName: pair.attributes.exercise.data.attributes.name,
//                 exerciseId: pair.attributes.exercise.data.id,
//                 sets: Array.from({ length: setsCount }, (_, index) => ({
//                     targetReps: targetReps[index] || '',
//                     reps: '',
//                     weight: ''
//                 }))
//             };
//         });
    
//         console.log("workoutPairs:", workoutPairs); // Logging workoutPairs for debugging
//         setWorkoutData(workoutPairs);
//     };

//     const handleInputChange = (pairIndex, setIndex, field, value) => {
//         const updatedData = [...workoutData];
//         updatedData[pairIndex].sets[setIndex][field] = value;
//         setWorkoutData(updatedData);
//     };

//     const confirmCloseModal = () => {
//         const confirmed = window.confirm('Er du sikker på at du vil avslutte økta?');
//         if (confirmed) {
//             setShowModal(false);
//         }
//     };

//     const confirmEndWorkout = async () => {
//         const confirmed = window.confirm('Er du sikker på at du vil avslutte økta?');
//         if (confirmed) {
//             endWorkout();
//         }
//     };

//     const endWorkout = async () => {
//         try {
//             // Create a new workout session
//             const workoutSessionResponse = await axiosAuthFetch(`${API_BASE_URL}/workout-sessions`, {
//                 data: {
//                     client: clientID,
//                     program: currentProgram.id,
//                     date_performed: new Date(),
//                 },
//             }, 'POST');

//             const workoutSession = workoutSessionResponse.data;

//             // Create exercise logs for each pair in the workout data
//             for (const data of workoutData) {
//                 for (let setIndex = 0; setIndex < data.sets.length; setIndex++) {
//                     const set = data.sets[setIndex];
//                     await axiosAuthFetch(`${API_BASE_URL}/exercise-logs`, {
//                         data: {
//                             exercise: data.exerciseId,
//                             sett: setIndex + 1,
//                             reps: set.reps,
//                             vekt: set.weight,
//                             client_comment: '',
//                             workout_session: workoutSession.id,
//                         },
//                     }, 'POST');
//                 }
//             }
//             alert('Økt lagret!')
//             setShowModal(false); // Close the modal
//         } catch (error) {
//             console.error("Error ending workout session:", error);
//         }
//     };

//     const AccordionContent = ({ program, pairs }) => {
//         return (
//             <div key={program.id}>
//                 <div className={templateCss.accordionCards}>
//                     {pairs.map((pair, index) => {
//                         const { description, comment, exercise } = pair.attributes;
//                         return (
//                             <div className={`${templateCss.openAccordionContainer1}`} key={pair.id}>
//                                 <div className={templateCss.accordionCardImageContainer}
//                                     style={{ backgroundImage: `url(${exercise.data.attributes.image.data[0].attributes.url})` }}>
//                                 </div>
//                                 <div className={templateCss.accordionContainer2}>
//                                     <p className={templateCss.exName}>{exercise.data.attributes.name}</p>
//                                     <p className={templateCss.setsReps}>Sett: {description}</p>
//                                     <p className={templateCss.comment}>Reps: {comment}</p>
//                                 </div>
//                             </div>
//                         );
//                     })}
//                 </div>
//                 <div className={templateCss.buttonContainer}>
//                     <div className={templateCss.startButton} onClick={() => openWorkoutModal(program)}>START ØKT</div>
//                 </div>
//             </div>
//         );
//     }

//     const filteredPrograms = programs.filter(program =>
//         program.attributes.programName.toLowerCase().includes(searchText.toLowerCase())
//     );

//     const formatTime = (time) => {
//         const minutes = Math.floor(time / 60);
//         const seconds = time % 60;
//         return `${minutes}m ${seconds}s`;
//     };


//     return (
//         <div className={templateCss.container1}>
//             <p className={templateCss.overskrift}>DINE PROGRAM</p>
//             <input
//                 className={templateCss.search}
//                 type="text"
//                 placeholder="Søk etter program..."
//                 value={searchText}
//                 onChange={(e) => setSearchText(e.target.value)}
//             />
//             <div className={templateCss.accordionContainer}>
//                 {filteredPrograms.map((program) => {
//                     const { programName, pairs, cover_image } = program.attributes;
//                     const imageUrl = cover_image.data.attributes.images.data[0].attributes.url;
//                     console.log(program.attributes);

//                     return (
//                         <div className={templateCss.accordionHeaderContainer} key={program.id}>
//                             <CustomAccordion
//                                 name={programName}
//                                 imageUrl={imageUrl}
//                                 content={<AccordionContent
//                                     pairs={pairs.data}
//                                     program={program}
//                                 />}
//                             />
//                         </div>
//                     );
//                 })}
//             </div>
//             {currentProgram && (
//                 <CustomAlertModal
//                     show={showModal}
//                     handleClose={confirmCloseModal}
//                     content={
//                         <div className={templateCss.modalContent}>
//                             <h2>{currentProgram.attributes.programName}</h2>
//                             <div className={templateCss.timerContainer}>
//                                 <div className={templateCss.timer}>Tid: {formatTime(elapsedTime)}</div>
//                             </div>
//                             {workoutData.map((data, pairIndex) => (
//                                 <div key={data.pairId} className={templateCss.exerciseCard}>
//                                     <div className={templateCss.cardHeader}>
//                                         <div className={templateCss.cardHeaderImageContainer} style={{
//                                             backgroundImage: `url(${currentProgram.attributes.pairs.data[pairIndex].attributes.exercise.data.attributes.image.data[0].attributes.url})`
//                                         }}
//                                         />
//                                         <div className={templateCss.exerciseNameDiv}>
//                                             <p className={templateCss.exerciseName}>{data.exerciseName}</p>
//                                         </div>
//                                     </div>
//                                     <div className={templateCss.subHeader}>
//                                         <div className={templateCss.subSet}>
//                                             Sett
//                                         </div>
//                                         <div className={templateCss.subReps}>
//                                             Rep-mål
//                                         </div>
//                                         <div className={templateCss.subReps}>
//                                             Reps gjort
//                                         </div>
//                                         <div className={templateCss.subReps}>
//                                             Vekt
//                                         </div>
//                                     </div>
//                                     {data.sets.map((set, setIndex) => (
//                                         <div key={setIndex} className={templateCss.set}>
//                                             {setIndex + 1}. 
//                                             <div className={templateCss.presetReps}>
//                                                 {set.targetReps}
//                                             </div>
//                                             <input
//                                                 type="tel"
//                                                 value={set.reps}
//                                                 onChange={(e) => handleInputChange(pairIndex, setIndex, 'reps', e.target.value)}
//                                                 placeholder="Reps"
//                                                 className={`${templateCss.input} ${templateCss.setInput}`}
//                                             />
//                                             <input
//                                                 type="tel"
//                                                 value={set.weight}
//                                                 onChange={(e) => handleInputChange(pairIndex, setIndex, 'weight', e.target.value)}
//                                                 placeholder="Vekt (kg)"
//                                                 className={templateCss.input}
//                                             />
//                                         </div>
//                                     ))}
//                                 </div>
//                             ))}
//                         </div>
//                     }
//                     buttonLabel="AVSLUTT ØKT"
//                     submit={confirmEndWorkout}
//                     modalTitle="Track din økt!"
//                 />
//             )}
//         </div>
//     );
// }

// import React, { useState, useEffect, useRef } from 'react';
// import { axiosAuthFetch } from '../../../../helpers/axios';
// import Loader from '../../../global/loader';
// import templateCss from '../template/template.module.css';
// import styles from './styles.module.css';
// import { getLocalStorageItem } from '../../../global/storage';
// import { API_BASE_URL, API_GRAPHQL } from '../../../../constants';
// import CustomAlertModal from '../../../global/customModal';

// export default function ClientTemplatePage() {
//   const [programs, setPrograms] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isError, setIsError] = useState(false);
//   const userInfo = getLocalStorageItem('limitless-user');
//   const clientID = userInfo.user.id;
//   const [searchText, setSearchText] = useState('');
//   const [currentProgram, setCurrentProgram] = useState(null);
//   const [workoutData, setWorkoutData] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [elapsedTime, setElapsedTime] = useState(0);
//   const timerRef = useRef(null);

//   // Ny state for å holde styr på visningen
//   const [currentView, setCurrentView] = useState('main');
//   const [selectedProgram, setSelectedProgram] = useState(null);

//   // Ny state for rating
//   const [rating, setRating] = useState(0);

//   const query = `query{
//     programs(filters:{users:{id:{eq:${clientID}}}}) {
//       data{
//         id
//         attributes{
//           programName
//           pairs{
//             data{
//               id
//               attributes{
//                 description
//                 comment
//                 exercise{
//                   data{
//                     id
//                     attributes{
//                       name
//                       description
//                       image{
//                         data{
//                             id
//                             attributes{
//                                 url
//                             }
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//           cover_image{
//             data{
//               id
//               attributes{
//                 images{
//                   data{
//                     id
//                     attributes{
//                       url
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   `;

//   useEffect(() => {
//     async function getPrograms() {
//       setIsLoading(true);
//       setIsError(false);
//       try {
//         const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
//         console.log(response.data);
//         setPrograms(response.data.programs.data);
//       } catch (error) {
//         console.error(error);
//         setIsError(true);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//     getPrograms();
//   }, [query]);

//   useEffect(() => {
//     if (showModal) {
//       timerRef.current = setInterval(() => {
//         setElapsedTime(prev => prev + 1);
//       }, 1000);
//     } else {
//       clearInterval(timerRef.current);
//     }

//     return () => clearInterval(timerRef.current);
//   }, [showModal]);

//   if (isLoading || !programs) {
//     return (
//       <div>
//         <Loader />
//       </div>
//     );
//   }

//   if (isError) {
//     return <div>Oida, her har det skjedd noe galt!</div>;
//   }

//   const openWorkoutModal = program => {
//     setCurrentProgram(program);
//     setShowModal(true);
//     setElapsedTime(0); // Tilbakestill timeren
//     setRating(0); // Tilbakestill rating

//     const workoutPairs = program.attributes.pairs.data.map(pair => {
//       const setsCount = parseInt(pair.attributes.description, 10);
//       const targetReps = pair.attributes.comment.split('/').map(rep => rep.trim());

//       console.log('targetReps:', targetReps); // Logging targetReps for debugging

//       return {
//         pairId: pair.id,
//         exerciseName: pair.attributes.exercise.data.attributes.name,
//         exerciseId: pair.attributes.exercise.data.id,
//         sets: Array.from({ length: setsCount }, (_, index) => ({
//           targetReps: targetReps[index] || '',
//           reps: '',
//           weight: '',
//         })),
//       };
//     });

//     console.log('workoutPairs:', workoutPairs); // Logging workoutPairs for debugging
//     setWorkoutData(workoutPairs);
//   };

//   const handleInputChange = (pairIndex, setIndex, field, value) => {
//     const updatedData = [...workoutData];

//     if (field === 'weight') {
//         // Erstatt komma med punktum for å håndtere desimaltegn
//         value = value.replace(',', '.');
  
//         // Validerer at inndataene er et gyldig tall
//         if (!/^\d*\.?\d*$/.test(value)) {
//           // Hvis ugyldig inndata, ignorer endringen
//           return;
//         }
//       }

//     updatedData[pairIndex].sets[setIndex][field] = value;
//     setWorkoutData(updatedData);
//   };

//   const confirmCloseModal = () => {
//     const confirmed = window.confirm('Er du sikker på at du vil avslutte økta?');
//     if (confirmed) {
//       setShowModal(false);
//       clearInterval(timerRef.current);
//       setElapsedTime(0);
//     }
//   };

//   const confirmEndWorkout = async () => {
//     const confirmed = window.confirm('Er du helt sikker på at du vil avslutte økta? Du kan ikke endre inputs etter at økta er lagret');
//     if (confirmed) {
//       endWorkout();
//     }
//   };

//   const endWorkout = async () => {
//     try {
//         clearInterval(timerRef.current);

//         // Beregn varighet i ønsket format
//         const durationInSeconds = elapsedTime;
//         const hours = Math.floor(durationInSeconds / 3600);
//         const minutes = Math.floor((durationInSeconds % 3600) / 60);
//         const seconds = durationInSeconds % 60;
    
//         const duration = `${hours.toString().padStart(2, '0')}:${minutes
//           .toString()
//           .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    

//       // Opprett en ny treningsøkt med varighet og rating
//       const workoutSessionResponse = await axiosAuthFetch(
//         `${API_BASE_URL}/workout-sessions`,
//         {
//           data: {
//             client: clientID,
//             program: currentProgram.id,
//             date_performed: new Date(),
//             duration: duration,
//             rating: rating,
//           },
//         },
//         'POST'
//       );

//       const workoutSession = workoutSessionResponse.data;

//       // Opprett treningslogger for hvert par i treningsdataene
//       for (const data of workoutData) {
//         for (let setIndex = 0; setIndex < data.sets.length; setIndex++) {
//           const set = data.sets[setIndex];

//           const weightValue = parseFloat(set.weight);

//           await axiosAuthFetch(
//             `${API_BASE_URL}/exercise-logs`,
//             {
//               data: {
//                 exercise: data.exerciseId,
//                 sett: setIndex + 1,
//                 reps: set.reps,
//                 vekt: weightValue,
//                 client_comment: '',
//                 workout_session: workoutSession.id,
//               },
//             },
//             'POST'
//           );
//         }
//       }
//       alert('Økt lagret!');
//       setShowModal(false); // Lukk modalen
//     } catch (error) {
//       console.error('Error ending workout session:', error);
//     }
//   };

//   // Funksjon for å åpne detaljvisningen
//   const openWorkoutDetail = program => {
//     setSelectedProgram(program);
//     setCurrentView('detail'); // Oppdaterer visningen til detalj
//   };

//   // Funksjon for å gå tilbake til hovedsiden
//   const goBackToMain = () => {
//     setCurrentView('main');
//     setSelectedProgram(null);
//   };

//   const filteredPrograms = programs.filter(program =>
//     program.attributes.programName.toLowerCase().includes(searchText.toLowerCase())
//   );

//   const formatTime = time => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${minutes}m ${seconds}s`;
//   };

//   // StarRating-komponenten
//   const StarRating = ({ rating, setRating }) => {
//     const handleRating = index => {
//       setRating(index + 1);
//     };

//     return (
//       <div className={styles.stars}>
//         {[...Array(5)].map((star, index) => {
//           return (
//             <span
//               key={index}
//               className={`${styles.star} ${index < rating ? styles.filled : ''}`}
//               onClick={() => handleRating(index)}
//             >
//               ★
//             </span>
//           );
//         })}
//       </div>
//     );
//   };

//   return (
//     <div className={templateCss.container1}>
//       {currentView === 'main' && (
//         <>
//           <p className={templateCss.overskrift}>DINE PROGRAM</p>
//           <input
//             className={templateCss.search}
//             type="text"
//             placeholder="Søk etter program..."
//             value={searchText}
//             onChange={e => setSearchText(e.target.value)}
//           />
//           <div className={styles.programList}>
//             {filteredPrograms.map(program => {
//               //eslint-disable-next-line
//               const { programName, pairs, cover_image } = program.attributes;
//               const imageUrl = cover_image.data.attributes.images.data[0].attributes.url;
//               console.log(program.attributes);

//               return (
//                 <div
//                   className={styles.programItem}
//                   key={program.id}
//                   onClick={() => openWorkoutDetail(program)}
//                   style={{ backgroundImage: `url(${imageUrl})` }}
//                 >
//                   <div className={styles.text}>{programName}</div>
//                 </div>
//               );
//             })}
//           </div>
//         </>
//       )}

//       {currentView === 'detail' && selectedProgram && (
//         <div className={templateCss.detailView}>
//           <div className={templateCss.header}>
//             <div onClick={goBackToMain} className={styles.backButton}>
//               Tilbake
//             </div>
//             <h2 className={styles.programTitle}>{selectedProgram.attributes.programName}</h2>
//           </div>
//           <div className={styles.programDetails}>
//             {/* Vis detaljer om det valgte programmet */}
//             {selectedProgram.attributes.pairs.data.map((pair, index) => {
//               const { description, comment, exercise } = pair.attributes;
//               return (
//                 <div className={`${templateCss.openAccordionContainer1}`} key={pair.id}>
//                   <div
//                     className={templateCss.accordionCardImageContainer}
//                     style={{
//                       backgroundImage: `url(${exercise.data.attributes.image.data[0].attributes.url})`,
//                     }}
//                   ></div>
//                   <div className={templateCss.accordionContainer2}>
//                     <p className={templateCss.exName}>{exercise.data.attributes.name}</p>
//                     <p className={templateCss.setsReps}>Sett: {description}</p>
//                     <p className={templateCss.comment}>Reps: {comment}</p>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//           <div className={templateCss.buttonContainer}>
//             <div className={templateCss.startButton} onClick={() => openWorkoutModal(selectedProgram)}>
//               START ØKT
//             </div>
//           </div>
//         </div>
//       )}

//       {currentProgram && (
//         <CustomAlertModal
//           show={showModal}
//           handleClose={confirmCloseModal}
//           content={
//             <div className={templateCss.modalContent}>
//               <h2>{currentProgram.attributes.programName}</h2>
//               <div className={templateCss.timerContainer}>
//                 <div className={templateCss.timer}>Tid: {formatTime(elapsedTime)}</div>
//               </div>
//               {workoutData.map((data, pairIndex) => (
//                 <div key={data.pairId} className={templateCss.exerciseCard}>
//                   <div className={templateCss.cardHeader}>
//                     <div
//                       className={templateCss.cardHeaderImageContainer}
//                       style={{
//                         backgroundImage: `url(${currentProgram.attributes.pairs.data[
//                           pairIndex
//                         ].attributes.exercise.data.attributes.image.data[0].attributes.url})`,
//                       }}
//                     />
//                     <div className={templateCss.exerciseNameDiv}>
//                       <p className={templateCss.exerciseName}>{data.exerciseName}</p>
//                     </div>
//                   </div>
//                   <div className={templateCss.subHeader}>
//                     <div className={templateCss.subSet}>Sett</div>
//                     <div className={templateCss.subReps}>Rep-mål</div>
//                     <div className={templateCss.subReps}>Reps gjort</div>
//                     <div className={templateCss.subReps}>Vekt</div>
//                   </div>
//                   {data.sets.map((set, setIndex) => (
//                     <div key={setIndex} className={templateCss.set}>
//                       {setIndex + 1}.
//                       <div className={templateCss.presetReps}>{set.targetReps}</div>
//                       <input
//                         type="tel"
//                         value={set.reps}
//                         onChange={e => handleInputChange(pairIndex, setIndex, 'reps', e.target.value)}
//                         placeholder="Reps"
//                         className={`${templateCss.input} ${templateCss.setInput}`}
//                       />
//                       <input
//                         type="text"
//                         inputMode="decimal"
//                         value={set.weight}
//                         onChange={e => handleInputChange(pairIndex, setIndex, 'weight', e.target.value)}
//                         placeholder="Vekt (kg)"
//                         className={templateCss.input}
//                       />
//                     </div>
//                   ))}
//                 </div>
//               ))}
//               {/* Inkluder rating-komponenten */}
//               <div className={styles.inputRating}>
//                 <label>Rating:</label>
//                 <StarRating rating={rating} setRating={setRating} />
//               </div>
//             </div>
//           }
//           buttonLabel="AVSLUTT ØKT"
//           submit={confirmEndWorkout}
//           modalTitle="Track din økt!"
//         />
//       )}
//     </div>
//   );
// }

// import React, { useState, useEffect, useRef } from 'react';
// import { axiosAuthFetch } from '../../../../helpers/axios';
// import Loader from '../../../global/loader';
// import templateCss from '../template/template.module.css';
// import styles from './styles.module.css';
// import { getLocalStorageItem } from '../../../global/storage';
// import { API_BASE_URL, API_GRAPHQL } from '../../../../constants';
// import CustomAlertModal from '../../../global/customModal';

// export default function ClientTemplatePage() {
//   const [programs, setPrograms] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isError, setIsError] = useState(false);
//   const userInfo = getLocalStorageItem('limitless-user');
//   const clientID = userInfo.user.id;
//   const [searchText, setSearchText] = useState('');
//   const [currentProgram, setCurrentProgram] = useState(null);
//   const [workoutData, setWorkoutData] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [elapsedTime, setElapsedTime] = useState(0);
//   const timerRef = useRef(null);
//   const [workoutName, setWorkoutName] = useState('');
//   const [startTime, setStartTime] = useState(null);
//   const [endTime, setEndTime] = useState(null);

//   // Ny state for å holde styr på visningen
//   const [currentView, setCurrentView] = useState('main');
//   const [selectedProgram, setSelectedProgram] = useState(null);

//   // Ny state for rating
//   const [rating, setRating] = useState(0);

//   const query = `query{
//     programs(filters:{users:{id:{eq:${clientID}}}}) {
//       data{
//         id
//         attributes{
//           programName
//           pairs{
//             data{
//               id
//               attributes{
//                 description
//                 comment
//                 exercise{
//                   data{
//                     id
//                     attributes{
//                       name
//                       description
//                       image{
//                         data{
//                             id
//                             attributes{
//                                 url
//                             }
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//           cover_image{
//             data{
//               id
//               attributes{
//                 images{
//                   data{
//                     id
//                     attributes{
//                       url
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   `;

//   useEffect(() => {
//     async function getPrograms() {
//       setIsLoading(true);
//       setIsError(false);
//       try {
//         const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
//         console.log(response.data);
//         setPrograms(response.data.programs.data);
//       } catch (error) {
//         console.error(error);
//         setIsError(true);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//     getPrograms();
//   }, [query]);

//   useEffect(() => {
//     if (showModal) {
//       timerRef.current = setInterval(() => {
//         setElapsedTime(prev => prev + 1);
//       }, 1000);
//     } else {
//       clearInterval(timerRef.current);
//     }

//     return () => clearInterval(timerRef.current);
//   }, [showModal]);

//   if (isLoading || !programs) {
//     return (
//       <div>
//         <Loader />
//       </div>
//     );
//   }

//   if (isError) {
//     return <div>Oida, her har det skjedd noe galt!</div>;
//   }

//   const openWorkoutModal = program => {
//     setCurrentProgram(program);
//     setShowModal(true);
//     setElapsedTime(0); // Tilbakestill timeren
//     setRating(0); // Tilbakestill rating
//     setStartTime(new Date()); // Set the start time
//     setEndTime(null);
//     setWorkoutName(program.attributes.programName);
//     const workoutPairs = program.attributes.pairs.data.map((pair, index) => {
//       const setsCount = parseInt(pair.attributes.description, 10);
//       const targetReps = pair.attributes.comment.split('/').map(rep => rep.trim());

//       return {
//         pairId: pair.id,
//         exerciseName: pair.attributes.exercise.data.attributes.name,
//         exerciseId: pair.attributes.exercise.data.id,
//         order: index + 1, // Set the order based on the index of the exercise
//         sets: Array.from({ length: setsCount }, (_, index) => ({
//           targetReps: targetReps[index] || '',
//           reps: '',
//           weight: '',
//         })),
//       };
//     });

//     setWorkoutData(workoutPairs);
//   };

//   const handleInputChange = (pairIndex, setIndex, field, value) => {
//     const updatedData = [...workoutData];

//     if (field === 'weight') {
//       value = value.replace(',', '.');
//       if (!/^\d*\.?\d*$/.test(value)) {
//         return;
//       }
//     }

//     updatedData[pairIndex].sets[setIndex][field] = value;
//     setWorkoutData(updatedData);
//   };

//   const confirmCloseModal = () => {
//     const confirmed = window.confirm('Er du sikker på at du vil avslutte økta?');
//     if (confirmed) {
//       setShowModal(false);
//       clearInterval(timerRef.current);
//       setElapsedTime(0);
//     }
//   };

//   const confirmEndWorkout = async () => {
//     const confirmed = window.confirm('Er du helt sikker på at du vil avslutte økta? Du kan ikke endre inputs etter at økta er lagret');
//     if (confirmed) {
//       endWorkout();
//     }
//   };

//   const endWorkout = async () => {
//     try {
//       clearInterval(timerRef.current);

//       const durationInSeconds = elapsedTime;
//       const hours = Math.floor(durationInSeconds / 3600);
//       const minutes = Math.floor((durationInSeconds % 3600) / 60);
//       const seconds = durationInSeconds % 60;

//       const duration = `${hours.toString().padStart(2, '0')}:${minutes
//         .toString()
//         .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

//       const workoutSessionResponse = await axiosAuthFetch(
//         `${API_BASE_URL}/workout-sessions`,
//         {
//           data: {
//             client: clientID,
//             program: currentProgram.id,
//             date_performed: new Date(),
//             duration: duration,
//             rating: rating,
//             name: workoutName,
//           },
//         },
//         'POST'
//       );

//       const workoutSession = workoutSessionResponse.data;

//       // Create exercise logs, now including the 'order' field
//       for (const data of workoutData) {
//         for (let setIndex = 0; setIndex < data.sets.length; setIndex++) {
//           const set = data.sets[setIndex];

//           const weightValue = parseFloat(set.weight);

//           await axiosAuthFetch(
//             `${API_BASE_URL}/exercise-logs`,
//             {
//               data: {
//                 exercise: data.exerciseId,
//                 sett: setIndex + 1,
//                 reps: set.reps,
//                 vekt: weightValue,
//                 order: data.order, // Ensure the order is sent with each exercise log
//                 workout_session: workoutSession.id,
//               },
//             },
//             'POST'
//           );
//         }
//       }
//       alert('Økt lagret!');
//       setShowModal(false); // Close the modal after logging the workout
//     } catch (error) {
//       console.error('Error ending workout session:', error);
//     }
//   };

//   // Funksjon for å åpne detaljvisningen
//   const openWorkoutDetail = program => {
//     setSelectedProgram(program);
//     setCurrentView('detail');
//   };

//   // Funksjon for å gå tilbake til hovedsiden
//   const goBackToMain = () => {
//     setCurrentView('main');
//     setSelectedProgram(null);
//   };

//   const filteredPrograms = programs.filter(program =>
//     program.attributes.programName.toLowerCase().includes(searchText.toLowerCase())
//   );

//   const formatTime = time => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${minutes}m ${seconds}s`;
//   };

//   const StarRating = ({ rating, setRating }) => {
//     const handleRating = index => {
//       setRating(index + 1);
//     };

//     return (
//       <div className={styles.stars}>
//         {[...Array(5)].map((star, index) => {
//           return (
//             <span
//               key={index}
//               className={`${styles.star} ${index < rating ? styles.filled : ''}`}
//               onClick={() => handleRating(index)}
//             >
//               ★
//             </span>
//           );
//         })}
//       </div>
//     );
//   };

//   return (
//     <div className={templateCss.container1}>
//       {currentView === 'main' && (
//         <>
//           <p className={templateCss.overskrift}>DINE PROGRAM</p>
//           <input
//             className={templateCss.search}
//             type="text"
//             placeholder="Søk etter program..."
//             value={searchText}
//             onChange={e => setSearchText(e.target.value)}
//           />
//           <div className={styles.programList}>
//             {filteredPrograms.map(program => {
//               //eslint-disable-next-line
//               const { programName, pairs, cover_image } = program.attributes;
//               const imageUrl = cover_image.data.attributes.images.data[0].attributes.url;

//               return (
//                 <div
//                   className={styles.programItem}
//                   key={program.id}
//                   onClick={() => openWorkoutDetail(program)}
//                   style={{ backgroundImage: `url(${imageUrl})` }}
//                 >
//                   <div className={styles.text}>{programName}</div>
//                 </div>
//               );
//             })}
//           </div>
//         </>
//       )}

//       {currentView === 'detail' && selectedProgram && (
//         <div className={templateCss.detailView}>
//           <div className={styles.header} style={{ backgroundImage: `url(${selectedProgram?.attributes?.cover_image?.data?.attributes?.images?.data[0]?.attributes?.url})` }}>
//             <div onClick={goBackToMain} className={styles.backButton}>
//               Tilbake
//             </div>
//             <div className={styles.programTitle}>{selectedProgram.attributes.programName}</div>
//           </div>
//           <div className={styles.programDetails}>
//             {selectedProgram.attributes.pairs.data.map((pair, index) => {
//               const { description, comment, exercise } = pair.attributes;
//               return (
//                 <div className={`${templateCss.openAccordionContainer1}`} key={pair.id}>
//                   <div
//                     className={templateCss.accordionCardImageContainer}
//                     style={{
//                       backgroundImage: `url(${exercise.data.attributes.image.data[0].attributes.url})`,
//                     }}
//                   ></div>
//                   <div className={templateCss.accordionContainer2}>
//                     <p className={templateCss.exName}>{exercise.data.attributes.name}</p>
//                     <p className={templateCss.setsReps}>Sett: {description}</p>
//                     <p className={templateCss.comment}>Reps: {comment}</p>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//           <div className={templateCss.buttonContainer}>
//             <div className={templateCss.startButton} onClick={() => openWorkoutModal(selectedProgram)}>
//               START ØKT
//             </div>
//           </div>
//         </div>
//       )}

//       {currentProgram && (
//         <CustomAlertModal
//           show={showModal}
//           handleClose={confirmCloseModal}
//           content={
//             <div className={templateCss.modalContent}>
//               <h2>{currentProgram.attributes.programName}</h2>
//               <div className={templateCss.timerContainer}>
//                 <div className={templateCss.timer}>Tid: {formatTime(elapsedTime)}</div>
//               </div>
//               {workoutData.map((data, pairIndex) => (
//                 <div key={data.pairId} className={templateCss.exerciseCard}>
//                   <div className={templateCss.cardHeader}>
//                     <div
//                       className={templateCss.cardHeaderImageContainer}
//                       style={{
//                         backgroundImage: `url(${currentProgram.attributes.pairs.data[
//                           pairIndex
//                         ].attributes.exercise.data.attributes.image.data[0].attributes.url})`,
//                       }}
//                     />
//                     <div className={templateCss.exerciseNameDiv}>
//                       <p className={templateCss.exerciseName}>{data.exerciseName}</p>
//                     </div>
//                   </div>
//                   <div className={templateCss.subHeader}>
//                     <div className={templateCss.subSet}>Sett</div>
//                     <div className={templateCss.subReps}>Rep-mål</div>
//                     <div className={templateCss.subReps}>Reps gjort</div>
//                     <div className={templateCss.subReps}>Vekt</div>
//                   </div>
//                   {data.sets.map((set, setIndex) => (
//                     <div key={setIndex} className={templateCss.set}>
//                       {setIndex + 1}.
//                       <div className={templateCss.presetReps}>{set.targetReps}</div>
//                       <input
//                         type="tel"
//                         value={set.reps}
//                         onChange={e => handleInputChange(pairIndex, setIndex, 'reps', e.target.value)}
//                         placeholder="Reps"
//                         className={`${templateCss.input} ${templateCss.setInput}`}
//                       />
//                       <input
//                         type="text"
//                         inputMode="decimal"
//                         value={set.weight}
//                         onChange={e => handleInputChange(pairIndex, setIndex, 'weight', e.target.value)}
//                         placeholder="Vekt (kg)"
//                         className={templateCss.input}
//                       />
//                     </div>
//                   ))}
//                 </div>
//               ))}
//               {/* Inkluder rating-komponenten */}
//               <div className={styles.inputRating}>
//                 <label>Rating:</label>
//                 <StarRating rating={rating} setRating={setRating} />
//               </div>
//             </div>
//           }
//           buttonLabel="AVSLUTT ØKT"
//           submit={confirmEndWorkout}
//           modalTitle="Track din økt!"
//         />
//       )}
//     </div>
//   );
// }

import React, { useState, useEffect, useRef } from 'react';
import { axiosAuthFetch } from '../../../../helpers/axios';
import Loader from '../../../global/loader';
import templateCss from '../template/template.module.css';
import styles from './styles.module.css';
import { getLocalStorageItem } from '../../../global/storage';
import { API_BASE_URL, API_GRAPHQL } from '../../../../constants';
import CustomAlertModal from '../../../global/customModal';
import '../../../transitions.css';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';

export default function ClientTemplatePage() {
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const userInfo = getLocalStorageItem('limitless-user');
  const clientID = userInfo.user.id;
  const [searchText, setSearchText] = useState('');
  const [currentProgram, setCurrentProgram] = useState(null);
  const [workoutData, setWorkoutData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const timerRef = useRef(null);
  const [workoutName, setWorkoutName] = useState('');
  const [startTime, setStartTime] = useState(null); // Added startTime state
  //eslint-disable-next-line
  const [endTime, setEndTime] = useState(null);     // Added endTime startTime
  // const [isNavigatingForward, setIsNavigatingForward] = useState(true);
  const [clientComment, setClientComment] = useState('');
  // const [showPreviousWorkout, setShowPreviousWorkout] = useState(false);
  // const [previousWorkoutData, setPreviousWorkoutData] = useState(null);

  // New state to manage views
  const [currentView, setCurrentView] = useState('main');
  const [selectedProgram, setSelectedProgram] = useState(null);

  // New state for rating
  const [rating, setRating] = useState(0);

  const query = `query{
    programs(filters:{users:{id:{eq:${clientID}}}}) {
      data{
        id
        attributes{
          programName
          pairs{
            data{
              id
              attributes{
                description
                comment
                exercise{
                  data{
                    id
                    attributes{
                      name
                      description
                      image{
                        data{
                            id
                            attributes{
                                url
                            }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          cover_image{
            data{
              id
              attributes{
                images{
                  data{
                    id
                    attributes{
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `;

  useEffect(() => {
    async function getPrograms() {
      setIsLoading(true);
      setIsError(false);
      try {
        const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
        console.log(response.data);
        setPrograms(response.data.programs.data);
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
    getPrograms();
  }, [query]);

  useEffect(() => {
    if (showModal && startTime) {
      timerRef.current = setInterval(() => {
        const now = new Date();
        const elapsed = Math.floor((now - startTime) / 1000);
        setElapsedTime(elapsed);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [showModal, startTime]);

  if (isLoading || !programs) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (isError) {
    return <div>Oida, her har det skjedd noe galt!</div>;
  }

  // const fetchPreviousWorkout = async (programId) => {
  //   try {
  //     const response = await axiosAuthFetch(
  //       `${API_BASE_URL}/workout-sessions?filters[program][id][$eq]=${programId}&sort=date_performed:desc&limit=1&populate=exercise_logs.exercise.image`,
  //       {},
  //       'GET'
  //     );
  //     if (response.data.length > 0) {
  //       // setPreviousWorkoutData(response.data[0]); // Set the latest session as previous workout data
  //     }
  //   } catch (error) {
  //     console.error("Error fetching previous workout:", error);
  //   }
  // };

  // const openWorkoutModal = (program) => {
  //   setCurrentProgram(program);
  //   setShowModal(true);
  //   setElapsedTime(0); // Reset the timer
  //   setRating(0); // Reset rating
  //   setWorkoutName(program.attributes.programName);
  //   setStartTime(new Date()); // Set the start time
  //   setEndTime(null); // Reset end time

  //   const workoutPairs = program.attributes.pairs.data.map((pair, index) => {
  //     const setsCount = parseInt(pair.attributes.description, 10);
  //     const targetReps = pair.attributes.comment.split('/').map((rep) => rep.trim());

  //     return {
  //       pairId: pair.id,
  //       exerciseName: pair.attributes.exercise.data.attributes.name,
  //       exerciseId: pair.attributes.exercise.data.id,
  //       order: index + 1, // Set the order based on the index of the exercise
  //       sets: Array.from({ length: setsCount }, (_, index) => ({
  //         targetReps: targetReps[index] || '',
  //         reps: '',
  //         weight: '',
  //       })),
  //     };
  //   });

  //   setWorkoutData(workoutPairs);
  // };

  const openWorkoutModal = (program) => {
    setCurrentProgram(program);
    setShowModal(true);
    setElapsedTime(0);
    setRating(0);
    setWorkoutName(program.attributes.programName);
    setStartTime(new Date());
    // setShowPreviousWorkout(false);
    // setPreviousWorkoutData(null);

    const workoutPairs = program.attributes.pairs.data.map((pair, index) => {
      const setsCount = parseInt(pair.attributes.description, 10);
      const targetReps = pair.attributes.comment.split('/').map((rep) => rep.trim());

      return {
        pairId: pair.id,
        exerciseName: pair.attributes.exercise.data.attributes.name,
        exerciseId: pair.attributes.exercise.data.id,
        order: index + 1,
        sets: Array.from({ length: setsCount }, (_, index) => ({
          targetReps: targetReps[index] || '',
          reps: '',
          weight: '',
        })),
      };
    });

    setWorkoutData(workoutPairs);
    //fetchPreviousWorkout(program.id); // Fetch previous workout when opening the modal
  };

  // const togglePreviousWorkout = () => {
  //   setShowPreviousWorkout(!showPreviousWorkout); // Toggle between current and previous workout view
  // };

  const handleInputChange = (pairIndex, setIndex, field, value) => {
    const updatedData = [...workoutData];

    if (field === 'weight') {
      value = value.replace(',', '.');
      if (!/^\d*\.?\d*$/.test(value)) {
        return;
      }
    }

    updatedData[pairIndex].sets[setIndex][field] = value;
    setWorkoutData(updatedData);
  };

  const handleCommentChange = (e) => {
    setClientComment(e.target.value);
  }

  const confirmCloseModal = () => {
    const confirmed = window.confirm('Er du sikker på at du vil avslutte økta?');
    if (confirmed) {
      setShowModal(false);
      clearInterval(timerRef.current);
      setElapsedTime(0);
      setStartTime(null);
      setEndTime(null);
    }
  };

  const confirmEndWorkout = async () => {
    const confirmed = window.confirm(
      'Er du helt sikker på at du vil avslutte økta? Du kan ikke endre inputs etter at økta er lagret'
    );
    if (confirmed) {
      endWorkout();
    }
  };

  const endWorkout = async () => {
    setIsLoading(true);
    try {
      clearInterval(timerRef.current);
      const endTimeValue = new Date();
      setEndTime(endTimeValue); // Set the end time

      const durationInSeconds = Math.floor((endTimeValue - startTime) / 1000);

      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      const seconds = durationInSeconds % 60;

      const duration = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      const workoutSessionResponse = await axiosAuthFetch(
        `${API_BASE_URL}/workout-sessions`,
        {
          data: {
            client: clientID,
            program: currentProgram.id,
            date_performed: new Date(),
            duration: duration,
            rating: rating,
            name: workoutName,
            clients_comments: clientComment,
            start_time: startTime.toISOString(),
            end_time: endTimeValue.toISOString(),
          },
        },
        'POST'
      );

      const workoutSession = workoutSessionResponse.data;

      // Create exercise logs, now including the 'order' field
      for (const data of workoutData) {
        for (let setIndex = 0; setIndex < data.sets.length; setIndex++) {
          const set = data.sets[setIndex];

          const weightValue = parseFloat(set.weight);

          await axiosAuthFetch(
            `${API_BASE_URL}/exercise-logs`,
            {
              data: {
                exercise: data.exerciseId,
                sett: setIndex + 1,
                reps: set.reps,
                vekt: weightValue,
                order: data.order, // Ensure the order is sent with each exercise log
                workout_session: workoutSession.id,
              },
            },
            'POST'
          );
        }
      }
      alert('Økt lagret!');
      setShowModal(false); // Close the modal after logging the workout
    } catch (error) {
      console.error('Error ending workout session:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to open detail view
  const openWorkoutDetail = (program) => {
    //setIsNavigatingForward(true);
    setSelectedProgram(program);
    setCurrentView('detail');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to go back to main view
  const goBackToMain = () => {
    //setIsNavigatingForward(false);
    setCurrentView('main');
    setSelectedProgram(null);
  };

  const filteredPrograms = programs.filter((program) =>
    program.attributes.programName.toLowerCase().includes(searchText.toLowerCase())
  );

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours > 0 ? hours + 'h ' : ''}${minutes}m ${seconds}s`;
  };

  const StarRating = ({ rating, setRating }) => {
    const handleRating = (index) => {
      setRating(index + 1);
    };

    return (
      <div className={styles.stars}>
        {[...Array(5)].map((star, index) => {
          return (
            <span
              key={index}
              className={`${styles.star} ${index < rating ? styles.filled : ''}`}
              onClick={() => handleRating(index)}
            >
              ★
            </span>
          );
        })}
      </div>
    );
  };


  return (
    // <TransitionGroup component={null}>
    //   <CSSTransition
    //     key={currentView}
    //     timeout={300}
    //     classNames={
    //       isNavigatingForward
    //         ? {
    //             enter: styles.slideForwardEnter,
    //             enterActive: styles.slideForwardEnterActive,
    //             exit: styles.slideForwardExit,
    //             exitActive: styles.slideForwardExitActive,
    //           }
    //         : {
    //             enter: styles.slideBackwardEnter,
    //             enterActive: styles.slideBackwardEnterActive,
    //             exit: styles.slideBackwardExit,
    //             exitActive: styles.slideBackwardExitActive,
    //           }
    //     }
    //   >
        <div className={`${styles.container1}`}>
          {currentView === 'main' && (
            <div className={ `${styles.sub1} appearing-container`}>
              <p className={templateCss.overskrift}>DINE PROGRAM</p>
              <input
                className={templateCss.search}
                type="text"
                placeholder="Søk etter program..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className={styles.programList}>
                {filteredPrograms.map((program) => {
                  //eslint-disable-next-line
                  const { programName, pairs, cover_image } = program.attributes;
                  const imageUrl = cover_image.data.attributes.images.data[0].attributes.url;
  
                  return (
                    <div
                      className={styles.programItem}
                      key={program.id}
                      onClick={() => openWorkoutDetail(program)}
                      style={{ backgroundImage: `url(${imageUrl})` }}
                    >
                      <div className={styles.text}>{programName}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
  
          {currentView === 'detail' && selectedProgram && (
            <div className={templateCss.detailView}>
              <div
                className={styles.header}
                style={{
                  backgroundImage: `url(${selectedProgram?.attributes?.cover_image?.data?.attributes?.images?.data[0]?.attributes?.url})`,
                }}
              >
                <div onClick={goBackToMain} className={styles.backButton}>
                  Tilbake
                </div>
                <div className={styles.programTitle}>{selectedProgram.attributes.programName}</div>
              </div>
              <div className={styles.programDetails}>
                {selectedProgram.attributes.pairs.data.map((pair, index) => {
                  const { description, comment, exercise } = pair.attributes;
                  return (
                    <div className={`${templateCss.openAccordionContainer1}`} key={pair.id}>
                      <div
                        className={templateCss.accordionCardImageContainer}
                        style={{
                          backgroundImage: `url(${exercise.data.attributes.image.data[0].attributes.url})`,
                        }}
                      ></div>
                      <div className={templateCss.accordionContainer2}>
                        <p className={templateCss.exName}>{exercise.data.attributes.name}</p>
                        <p className={templateCss.setsReps}>Sett: {description}</p>
                        <p className={templateCss.comment}>Reps: {comment}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={templateCss.buttonContainer}>
                <div
                  className={templateCss.startButton}
                  onClick={() => openWorkoutModal(selectedProgram)}
                >
                  START ØKT
                </div>
              </div>
            </div>
          )}
  
          {currentProgram && (
            <CustomAlertModal
              show={showModal}
              handleClose={confirmCloseModal}
              content={
                <div className={templateCss.modalContent}>
                  <h2>{currentProgram.attributes.programName}</h2>
                  <div className={templateCss.timerContainer}>
                    <div className={templateCss.timer}>Tid: {formatTime(elapsedTime)}</div>
                  </div>
                  {workoutData.map((data, pairIndex) => (
                    <div key={data.pairId} className={templateCss.exerciseCard}>
                      <div className={templateCss.cardHeader}>
                        <div
                          className={templateCss.cardHeaderImageContainer}
                          style={{
                            backgroundImage: `url(${currentProgram.attributes.pairs.data[
                              pairIndex
                            ].attributes.exercise.data.attributes.image.data[0].attributes.url})`,
                          }}
                        />
                        <div className={templateCss.exerciseNameDiv}>
                          <p className={templateCss.exerciseName}>{data.exerciseName}</p>
                        </div>
                      </div>
                      <div className={templateCss.subHeader}>
                        <div className={templateCss.subSet}>Sett</div>
                        <div className={templateCss.subReps}>Rep-mål</div>
                        <div className={templateCss.subReps}>Reps gjort</div>
                        <div className={templateCss.subReps}>Vekt</div>
                      </div>
                      {data.sets.map((set, setIndex) => (
                        <div key={setIndex} className={templateCss.set}>
                          {setIndex + 1}.
                          <div className={templateCss.presetReps}>{set.targetReps}</div>
                          <input
                            type="tel"
                            value={set.reps}
                            onChange={(e) =>
                              handleInputChange(pairIndex, setIndex, 'reps', e.target.value)
                            }
                            placeholder="Reps"
                            className={`${templateCss.input} ${templateCss.setInput}`}
                          />
                          <input
                            type="text"
                            inputMode="decimal"
                            value={set.weight}
                            onChange={(e) =>
                              handleInputChange(pairIndex, setIndex, 'weight', e.target.value)
                            }
                            placeholder="Vekt (kg)"
                            className={templateCss.input}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                  <div className={styles.clientComment}>
                    <label>Din kommentar:</label>
                    <textarea 
                      className={styles.clientCommentBox}
                      value={clientComment}
                      onChange={handleCommentChange}
                      rows="6"
                    >
                    </textarea>
                  </div>
                  <div className={styles.inputRating}>
                    <label>Rating:</label>
                    <StarRating rating={rating} setRating={setRating} />
                  </div>
                </div>
              }
              buttonLabel="AVSLUTT ØKT"
              submit={confirmEndWorkout}
              modalTitle="Track din økt!"
            />
          )}
        </div>
    //   </CSSTransition>
    // </TransitionGroup>
  );
}
