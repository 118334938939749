import React, { useState, useEffect } from 'react';
import clientsCSS from './clients.module.css';
import { axiosAuthFetch } from '../../../helpers/axios';
import { API_BASE_URL, API_GRAPHQL } from '../../../constants';
import Loader from '../../global/loader';
import defaultImage from '../../../images/blue_man.png';
import Image from 'react-bootstrap/Image';
//import CoachCommunication from '../../global/communication/coachSide';
//import CoachSideCheckInCards from '../../global/forms/check-in/coachSide';
import ClientCards from '../../global/ClientCard';
import ExerciseBank from '../../global/exerciseBank';
//import CreateIngredient from '../../global/forms/createIngredient';
import { axiosImageAuthFetch } from '../../../helpers/axios';
import CoachCards from '../../global/coachCards';
import Modal from 'react-bootstrap/Modal';
import AddCoachForm from '../../global/forms/addCoach';
//import CreateMealForm from '../../global/forms/createMeal';
//import NutritionForm from '../nutrition';

const CoachesHomePage = ({user}) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [clients, setClients] = useState([]);
    const coachId = user.coachID;
    const [profilePicture, setProfilePicture] = useState('');
    const [coachInfo, setCoachInfo] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [aboutText, setAboutText] = useState('');
    const [image, setImage] = useState();
    const isAdmin = user.isAdmin;
    //eslint-disable-next-line
    const [matplans, setMatPlans] = useState([]);
    //const [programs, setPrograms] = useState([]);
    //eslint-disable-next-line
    const [checkIns, setCheckIns] = useState([]);
    const [createCoachModalOpen, setCreateCoachModalOpen] = useState(false);
    //const [secondImage, setSecondImage] = useState('');
    //const [isCoach, setIsCoach] = useState(false);
    // if(coachId) {
    //     setIsCoach(true);
    // }

    const query = ` 
                query{
                    coaches(filters: {id:{eq:${coachId}}}) {
                    data{
                        id
                        attributes{
                            About
                            user{
                                data{
                                    id
                                    attributes{
                                        profile_picture{
                                            data{
                                                id
                                                attributes{
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            clients{
                                data{
                                    id
                                    attributes{
                                        username
                                        programs{
                                            data{
                                                id
                                                attributes{
                                                    programName
                                                }
                                            }
                                        }
                                        matplan{
                                            data{
                                                id
                                                attributes{
                                                    Name
                                                }
                                            }
                                        }   
                                        workout_sessions{
                                            data{
                                                id
                                                attributes{
                                                    date_performed
                                                }
                                            }
                                        }
                                        profile_picture{
                                            data{
                                                id
                                                attributes{
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    }
                }
            `;

    useEffect(() => {
        async function getUsers() {
            setIsLoading(true);
            setIsError(false);
            try {
                const response = await axiosAuthFetch(`${API_GRAPHQL}`, {query}, 'POST');
                console.log(response);
                // console.log(response.data.coaches.data[0].attributes.clients.data);
                // setClients(response.data.coaches.data[0].attributes.clients.data);
                const filteredClients = response.data.coaches.data[0].attributes.clients.data.filter(client => client.id !== user.id.toString());
                setClients(filteredClients);
                const image = response?.data?.coaches?.data[0]?.attributes?.user?.data?.attributes?.profile_picture?.data?.attributes?.url;
                setProfilePicture(image || defaultImage);
                const info = response?.data?.coaches?.data[0]?.attributes;
                setCoachInfo(info);
                setAboutText(info?.About || '');
                //setPrograms(response?.data?.coaches?.data[0]?.attributes?.programs?.data);
                setCheckIns(response?.data?.coaches?.data[0]?.attributes?.check_ins?.data);
                // console.log(users);
            } catch(error) {
                console.error(error)
                setIsError(true)
            } finally {
                setIsLoading(false);
            }
        }
        getUsers();

    }, [query, user.id])


    if(isLoading || !clients) {
        return(<Loader />)
    }
    if(isError) {
        return(<div> Oida, her gikk noe galt! Vennligst prøv å last inn siden på nytt!</div>)
    }

    const handleEditClick = () => {
        setIsEditing(true);
    }

    const handleInputChange = (e) => {
        if(e.target.value.length <= 150) {
            setAboutText(e.target.value);
        }
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    }

    const showModal = () => {
        setCreateCoachModalOpen(true);
    }

    const handleCloseModal = () => {
        setCreateCoachModalOpen(false);
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        const formData = new FormData();

        formData.append('files', image);

    try{
        const uploadResponse = await axiosImageAuthFetch(`${API_BASE_URL}/upload/`, formData, 'POST');
        console.log(uploadResponse);

        const uploadedImage = uploadResponse[0].id;
        console.log(uploadedImage);

        const updateData = {
            profile_picture: uploadedImage,
        }

        const response = await axiosAuthFetch(`${API_BASE_URL}/users/${user.id}`, updateData, 'PUT');

        console.log(response);
        alert('Profilbilde byttet!')
        setIsLoading(false);
        window.location.reload();
    } catch(error){
        console.error(error);
        setIsLoading(false);
    }
    };

    const handleSaveClick = async () => {
        try {
            const updatedInfo = {...coachInfo, About: aboutText};
            setCoachInfo(updatedInfo);
            setIsEditing(false);

            await axiosAuthFetch(`${API_BASE_URL}/coaches/${coachId}`, {data: updatedInfo}, 'PUT');
        } catch(error) {
            console.error('Error saving the info', error)
        }
    }

    // const renderStars = (rating) => {
    //     const stars = [];
    //     for (let i = 1; i <= 5; i++) {
    //         stars.push(
    //             <span key={i} className={i <= rating ? clientsCSS.starFilled : clientsCSS.starEmpty}>
    //                 &#9733;
    //             </span>
    //         );
    //     }
    //     return stars;
    // };

    // const redirectToPrograms = () => {
    //     window.location="/training/template";
    // }

    return(
        <div className={`${clientsCSS.container} appearing-container`}>
            <div className={clientsCSS.container4}>
                <div className={clientsCSS.header}>
                    <div className={clientsCSS.image}>
                        <div className={clientsCSS.profilePictureContainer}>
                            <Image className={clientsCSS.profilePicture} src={profilePicture} alt="profile-picture" fluid/>
                        </div>
                    </div>
                    <div className={clientsCSS.uploadProfileImageConntainer}>
                        <form className={clientsCSS.form}>
                            <label>Last opp profilbilde:</label>
                            <input type="file" onChange={handleImageChange} />
                            <div className={clientsCSS.formButton} onClick={handleSubmit}>Last opp</div>
                        </form>
                    </div>
                    <div className={clientsCSS.coachInfo}>
                        <div className={clientsCSS.underoverskrift}>Velkommen {user.username}! 👋🏼</div>
                    </div>
                </div>
                <div className={clientsCSS.sub1}>
                    <div className={clientsCSS.underoverskrift2}>Om deg</div>
                    <div className={clientsCSS.about}>
                        {coachInfo.About ? (
                            <>
                                <p>{coachInfo.About}</p>
                                <div className={clientsCSS.editButton} onClick={handleEditClick}>Rediger</div>
                            </>
                        ): (
                            <>
                                <p>Legg til informasjon om deg selv</p>
                                <p>(maks 150 tegn)</p>
                                <div className={clientsCSS.editButton} onClick={handleEditClick}>Rediger</div>
                            </>
                        )}
                        {isEditing && (
                            <div>
                                <textarea
                                    className={clientsCSS.area}
                                    value={aboutText}
                                    onChange={handleInputChange}
                                    maxLength="150"
                                />
                                <p>Maks 150 tegn</p>
                                <div className={clientsCSS.editButton} onClick={handleSaveClick}>Lagre</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={clientsCSS.sub4}>
                    <ExerciseBank />
                </div>
            </div>
            <div className={clientsCSS.container2}>
                {/* <div className={clientsCSS.sub2}>
                    <CreateIngredient />
                </div> */}
                <div className={clientsCSS.sub3}>
                    <div className={clientsCSS.underoverskrift2}>Dine klienter</div>
                    <ClientCards clients={clients}/>
                </div>
                <div className={clientsCSS.container3}>
                    {isAdmin && (
                        <div className={clientsCSS.sub3}>
                            <div className={clientsCSS.sub3Header}>
                                <div className={clientsCSS.underoverskrift2}>Coacher</div>
                                <div className={clientsCSS.addCoachButton} onClick={showModal}>Legg til ny coach</div>
                            </div>
                            <CoachCards />
                            <Modal size="lg" className={clientsCSS.modal} show={createCoachModalOpen} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Coach forespørsler</Modal.Title>
                                </Modal.Header>
                                    <Modal.Body>
                                        <AddCoachForm />
                                    </Modal.Body>
                                <Modal.Footer>
                                    <div className={clientsCSS.closeButton} onClick={handleCloseModal}>
                                        Lukk
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    )}
                </div>
                {/* <div className={clientsCSS.container4}>
                    <div className={clientsCSS.sub4}>
                      <div className={clientsCSS.underoverskrift2}>Lag matplan</div>  
                    </div>
                    <div className={clientsCSS.sub5}>
                        <CreateMealForm />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default CoachesHomePage;