import { useState, useEffect } from 'react';
import { API_GRAPHQL } from '../../constants';
import { axiosAuthFetch } from '../../helpers/axios';
import { getLocalStorageItem } from '../../components/global/storage';

export function useWorkouts() {
    const [workouts, setWorkouts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const user = getLocalStorageItem('limitless-user');
    const id = user.user.id;
    //console.log(id);
    const query = `
        query{
            usersPermissionsUser(id:${id}){
                data{
                    id
                    attributes{
                        workout_sessions{
                            data{
                                id
                                attributes{
                                    rating
                                    duration
                                    date_performed
                                    clients_comments
                                    exercise_logs{
                                        data{
                                            id
                                            attributes{
                                                sett
                                                reps
                                                vekt
                                                exercise{
                                                    data{
                                                        id
                                                        attributes{
                                                            name
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `

    useEffect(() => {
        async function fetchExercises() {
            try {
                setIsLoading(true);
                const getWorkoutsURL = `${API_GRAPHQL}`;
                const response = await axiosAuthFetch(`${getWorkoutsURL}`, {query}, 'POST');
                //console.log(response.data.usersPermissionsUser.data.attributes.workout_sessions.data);
                setWorkouts(response.data.usersPermissionsUser.data.attributes.workout_sessions.data);
                console.log('fra useHook', response.data.usersPermissionsUser.data.attributes.workout_sessions.data);
            } catch (error) {
                console.error('Error fetching exercises:', error);
                // Handle feil, for eksempel vise en feilmelding til brukeren
            } finally {
                setIsLoading(false);
            }
        }

        fetchExercises();

        // Returner en funksjon fra useEffect for å rydde opp (clean up)
        return () => {
            // Her kan du legge til ryddekode hvis nødvendig
        };
    }, [query]); // Tomt avhengighetsarray betyr at effekten kun skal kjøres én gang ved montering

    return {isLoading, workouts};
}