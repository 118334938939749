import React, { useState, useEffect } from 'react';
import update from './update.module.css';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL } from '../../../../constants';
import Loader from '../../loader';
import { v4 as uuidv4 } from 'uuid';
import { SearchableExercisesDropdown } from '../../searchableDropdown';
import CustomAlert from '../../customAlert';

const UpdateForm = ({program, options}) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    
    // const [pairs, setPairs] = useState([{id: uuidv4(), description: '', exercise: '', comment: ''}]);
    const [pairs, setPairs] = useState(program.attributes.pairs.data.map(pair => ({
        id: pair.id,
        description: pair.attributes.description,
        comment: pair.attributes.comment,
        exercise: pair.attributes.exercise.data.id,
        pairID: pair.id,
      })));
    const [programName, setProgramName] = useState(program.attributes.programName);

    const initializePairs = (program) => {
        return program.attributes.pairs.data.map(pair => ({
            id: uuidv4(),
            description: pair.attributes.description,
            exercise: pair.attributes.exercise.data,
            comment: pair.attributes.comment || '',
            pairID: pair.id,
        }));
    };

    const handleAlertClose = () => {
        setShowAlert(false);
        window.location.reload();
    }

    // useEffect for å sette initialverdiene for pairs
    useEffect(() => {
        if (program && program.attributes.pairs) {
            setPairs(initializePairs(program));
        }
    }, [program]);

    const handleExerciseChange = (pairId, selectedOption) => {
        setPairs(prevPairs =>
            prevPairs.map(pair =>
                pair.id === pairId ? { ...pair, exercise: selectedOption } : pair
            )
        );
    };

    const handleDescriptionChange = (pairId, event) => {
        setPairs(prevPairs =>
            prevPairs.map(pair =>
                pair.id === pairId ? {...pair, description: event.target.value} : pair));
    };

    const handleCommentChange = (pairId, event) => {
        setPairs(prevPairs =>
            prevPairs.map(pair =>
                pair.id === pairId ? { ...pair, comment: event.target.value } : pair
            )
        );
    };

    const handleAddPair = () => {
        setPairs([...pairs, {id: uuidv4(), description: '', exercise: '' , comment: '', pairID: null}]);
    };

    const handleRemovePair = (pairId) => {
        setPairs(prevPairs => prevPairs.filter(pair => pair.id !== pairId))
    };

    const updateProgram = async (programId, programName, pairs) => {
        console.log('pairs', pairs);
        try {

            let updatedPairIds = [];

            for (const pair of pairs) {
                if(pair.pairID) {
                    await axiosAuthFetch(`${API_BASE_URL}/pairs/${pair.pairID}`, {
                        data: {
                        description: pair.description,
                        comment : pair.comment,
                        exercise: pair.exercise.id,
                        }
                    }, 'PUT');
                    updatedPairIds.push(pair.pairID);
                } else {
                    const response = await axiosAuthFetch(`${API_BASE_URL}/pairs`, {
                        data: {
                        description: pair.description,
                        comment: pair.comment,
                        exercise: pair.exercise.id,
                        }
                    }, 'POST');
                    updatedPairIds.push(response.data.id);
                } 
            } ;

            await axiosAuthFetch(`${API_BASE_URL}/programs/${programId}`, {
                data: {
                programName: programName,
                pairs: updatedPairIds,
                }
            }, 'PUT');
            setShowAlert(true);
            console.log('programmet ble opprettet')
        } catch(error) {
            console.error(error);
            setIsError(true);
        }
    }

    const handleCreateProgram = async () => {
        setIsLoading(true);
        try {
            await updateProgram(program.id, programName, pairs);
        } catch(error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    if(isLoading) {
        return(<div><Loader /></div>)
    }

    if(isError) {
        return(<div>Oida, her gikk noe galt! Vennligst prøv å laste inn siden på nytt!</div>)
    }


    return( 
        <div className={update.container1}>
            <input 
                className={update.writtenInput}
                placeholder={program.programName}
                value = {programName}
                onChange={(event) => setProgramName(event.target.value)}
                required
            />
            <div className={update.userText}>Velg øvelser</div>
            <CustomAlert 
                show={showAlert}
                handleClose={handleAlertClose}
                buttonLabel="Den er grei!"
                content="Programmet er oppdatert!"
                title="Suksess!"
            />
            <div className={update.cardContainer}>
                {pairs.map((pair, index) => (
                    <div className={update.container2} key={pair.id}>
                        <div className={update.underoverskrift}> Øvelse {index + 1}</div>
                        <SearchableExercisesDropdown
                            objects={options}
                            onSelect={(selectedOption) => handleExerciseChange(pair.id, selectedOption)}
                            selectedValue={pair.exercise}
                            id={`exercise-dropdown-${index}`}
                        />
                        <input
                            className={update.writtenInput}
                            placeholder="Sett"
                            value={pair.description}
                            onChange={(event) => handleDescriptionChange(pair.id, event)}
                        />
                        <input
                            className={update.writtenInput}
                            placeholder="Reps"
                            value={pair.comment}
                            onChange={(event) => handleCommentChange(pair.id, event)}
                        />
                        {index !== 0 && (
                            <button className={`${update.button} ${update.removeButton}`} onClick={() => handleRemovePair(pair.id)}>Fjern øvelse</button>
                        )}
                    </div>
                ))}
            </div>
            <button className={update.button} onClick={handleAddPair}>Legg til øvelse</button>
            <button className={`${update.button} ${update.createButton}`} onClick={handleCreateProgram}>Oppdater program</button>
        </div>
    )
}

export default UpdateForm;