// import React, { useState } from 'react';
// import styles from './styles.module.css';
// import Clock from '../../../../images/icons/timer.png';
// import Chef from '../../../../images/icons/chef-hat.png';
// import Serving from '../../../../images/icons/user (2).png';
// import IngredientIcon from '../../../../images/icons/harvest.png';
// import Image from 'react-bootstrap/Image';

// export default function MealDetail({ mealData, isMealInstance, onBack }) {
    
//     // const { name, prep_time, cooking_time, serving, meal_images, category, preparation, description, cooking_instructions  } = mealInstance.attributes.meal.data.attributes;
//     // const imageUrl = meal_images.data[0].attributes.image.data.attributes.url;
//     // const [activeTab, setActiveTab] = useState("description");

//     // // Beregning av næringsinnhold
//     // const calculateNutrition = () => {
//     //     let totalProtein = 0;
//     //     let totalCarbs = 0;
//     //     let totalFat = 0;
//     //     let totalKcals = 0;
    
//     //     mealInstance.attributes.custom_ingredients.forEach(ingredient => {
//     //         const { amount, unit, macros } = ingredient;
//     //         const quantity = parseFloat(amount);
    
//     //         if (unit === "stk") {
//     //             // Multipliser direkte med antall hvis enheten er "stk"
//     //             totalProtein += macros.protein * quantity;
//     //             totalCarbs += macros.carbs * quantity;
//     //             totalFat += macros.fat * quantity;
//     //             totalKcals += macros.kcals * quantity;
//     //         } else if (unit === "g") {
//     //             // Hvis enheten er gram, beregn basert på makroer per 100g
//     //             totalProtein += (macros.protein / 100) * quantity;
//     //             totalCarbs += (macros.carbs / 100) * quantity;
//     //             totalFat += (macros.fat / 100) * quantity;
//     //             totalKcals += (macros.kcals / 100) * quantity;
//     //         } else if (unit === "dl") {
//     //             // Anta at 1 dl ≈ 100g, og beregn makroene basert på dette
//     //             const gramsEquivalent = quantity * 100;
//     //             totalProtein += (macros.protein / 100) * gramsEquivalent;
//     //             totalCarbs += (macros.carbs / 100) * gramsEquivalent;
//     //             totalFat += (macros.fat / 100) * gramsEquivalent;
//     //             totalKcals += (macros.kcals / 100) * gramsEquivalent;
//     //         }
//     //     });
    
//     //     return {
//     //         totalProtein: Math.round(totalProtein),
//     //         totalCarbs: Math.round(totalCarbs),
//     //         totalFat: Math.round(totalFat),
//     //         totalKcals: Math.round(totalKcals),
//     //     };
//     // };

//     // const { totalProtein, totalCarbs, totalFat, totalKcals } = calculateNutrition();

//     const mealAttributes = isMealInstance ? mealData.attributes.meal.data.attributes : mealData.attributes;
//     const { name, prep_time, cooking_time, serving, meal_images, category, preparation, description, cooking_instructions } = mealAttributes;
//     const imageUrl = meal_images?.data[0]?.attributes?.image?.data?.attributes?.url;
//     const [activeTab, setActiveTab] = useState("description");

//     const calculateNutrition = () => {
//         let totalProtein = 0;
//         let totalCarbs = 0;
//         let totalFat = 0;
//         let totalKcals = 0;

//         const ingredients = isMealInstance
//             ? mealData.attributes.custom_ingredients
//             : mealData.attributes.meal_ingredients.data;

//         ingredients.forEach(ingredient => {
//             const { amount, unit, macros } = ingredient;
//             const quantity = parseFloat(amount);

//             if (unit === "stk") {
//                 totalProtein += macros.protein * quantity;
//                 totalCarbs += macros.carbs * quantity;
//                 totalFat += macros.fat * quantity;
//                 totalKcals += macros.kcals * quantity;
//             } else if (unit === "g") {
//                 totalProtein += (macros.protein / 100) * quantity;
//                 totalCarbs += (macros.carbs / 100) * quantity;
//                 totalFat += (macros.fat / 100) * quantity;
//                 totalKcals += (macros.kcals / 100) * quantity;
//             } else if (unit === "dl") {
//                 const gramsEquivalent = quantity * 100;
//                 totalProtein += (macros.protein / 100) * gramsEquivalent;
//                 totalCarbs += (macros.carbs / 100) * gramsEquivalent;
//                 totalFat += (macros.fat / 100) * gramsEquivalent;
//                 totalKcals += (macros.kcals / 100) * gramsEquivalent;
//             }
//         });

//         return {
//             totalProtein: Math.round(totalProtein),
//             totalCarbs: Math.round(totalCarbs),
//             totalFat: Math.round(totalFat),
//             totalKcals: Math.round(totalKcals),
//         };
//     };

//     const { totalProtein, totalCarbs, totalFat, totalKcals } = calculateNutrition();

//     return (
//         <div className={styles.detailContainer}>
//             <div className={styles.header}>
//                 <button className={styles.backButton} onClick={onBack}>Tilbake</button>
//             </div>
//             <div className={styles.name}>{name}</div>
//             <div className={styles.category}>{category}</div>
//             <div className={styles.mealImage} style={{ backgroundImage: `url(${imageUrl})` }} />
//             <div className={styles.prepInfo}>
//                 <div className={styles.prepChild}>
//                     <div className={styles.prepSubHeader}>
//                         <div className={styles.iconContainer}>
//                             <Image src={Clock} fluid alt="clock icon" />
//                         </div>
//                         <div className={styles.prepText}>Prep:</div>
//                     </div>
//                  <p><strong>{prep_time} min</strong></p>
//                 </div>
//                 <div className={styles.prepChild}>
//                     <div className={styles.prepSubHeader}>
//                         <div className={styles.iconContainer}>
//                             <Image src={Chef} fluid alt="chef icon" />
//                         </div>
//                         <div className={styles.prepText}>Cooking:</div>
//                     </div>
//                  <p><strong>{cooking_time} min</strong></p>
//                 </div>
//                 <div className={styles.prepChild}>
//                     <div className={styles.prepSubHeader}>
//                         <div className={styles.iconContainer}>
//                             <Image src={Serving} fluid alt="servings icon" />
//                         </div>
//                         <div className={styles.prepText}>Serving:</div>
//                     </div>
//                  <p><strong>{serving} serving</strong></p>
//                 </div>
//             </div>
//             <div className={styles.nutritionContainer}>
//                 <h3>Næringsinnhold</h3>
//                 <div className={styles.nutriSubHeader}>Per måltid</div>
//                 <div className={styles.nutriChild}>
//                     <div className={styles.nutriSub1}>
//                         <div className={styles.nutriP}>{totalKcals} kcal</div>
//                         <div className={styles.nutriS}>Kalorier</div>
//                     </div>
//                     <div className={styles.nutriSub1}>
//                         <div className={styles.nutriP}>{totalProtein}g</div>
//                         <div className={styles.nutriS}>Protein</div>
//                     </div>
//                     <div className={styles.nutriSub1}>
//                         <div className={styles.nutriP}> {totalCarbs}g</div>
//                         <div className={styles.nutriS}>Karbo</div>
//                     </div>
//                     <div className={styles.nutriSub1}>
//                         <div className={styles.nutriP}>{totalFat}g</div>
//                         <div className={styles.nutriS}>Fett</div>
//                     </div>
//                 </div>
//             </div>
//             <div className={styles.ingredientsContainer}>
//                 <h3>Ingredienser</h3>
//                 {mealInstance.attributes.custom_ingredients.map((ingredient) => (
//                     <div className={styles.ingredientChild} key={ingredient.ingredient_name}>
//                         <div className={styles.ingredientIconContainer}>
//                             <Image fluid alt="nutrients icon" src={IngredientIcon}/>
//                         </div>
//                         <div>{ingredient.ingredient_name}</div>
//                         <div>{ingredient.amount} {ingredient.unit}</div>
//                     </div>
//                 ))}
//             </div>
//             <div className={styles.tabsContainer}>
//                 <div className={styles.customTabs}>
//                     <button className={`${styles.tabButton} ${activeTab === "preparation" ? styles.activeTab : ""}`} onClick={() => setActiveTab("preparation")}>
//                         Prep
//                     </button>
//                     <button className={`${styles.tabButton} ${activeTab === "description" ? styles.activeTab : ""}`} onClick={() => setActiveTab("description")}>
//                         Beskrivelse
//                     </button>
//                     <button className={`${styles.tabButton} ${activeTab === "cookingInstructions" ? styles.activeTab : ""}`} onClick={() => setActiveTab("cookingInstructions")}>
//                         Instruksjoner
//                     </button>
//                 </div>

//                 <div className={styles.tabContent}>
//                     {activeTab === "preparation" && <p>{preparation}</p>}
//                     {activeTab === "description" && <p>{description}</p>}
//                     {activeTab === "cookingInstructions" && <p>{cooking_instructions}</p>}
//                 </div>
//             </div>
//         </div>
//     );
// }

// import React, { useState } from 'react';
// import styles from './styles.module.css';
// import Clock from '../../../../images/icons/timer.png';
// import Chef from '../../../../images/icons/chef-hat.png';
// import Serving from '../../../../images/icons/user (2).png';
// import IngredientIcon from '../../../../images/icons/harvest.png';
// import Image from 'react-bootstrap/Image';

// export default function MealDetail({ mealData, isMealInstance, onBack }) {
//     const mealAttributes = isMealInstance ? mealData.attributes.meal.data.attributes : mealData.attributes;
//     const { name, prep_time, cooking_time, serving, meal_images, category, preparation, description, cooking_instructions } = mealAttributes;
//     const imageUrl = meal_images?.data[0]?.attributes?.image?.data?.attributes?.url;
//     const [activeTab, setActiveTab] = useState("description");
//     console.log(mealData, isMealInstance);

//     const calculateNutrition = () => {
//         let totalProtein = 0;
//         let totalCarbs = 0;
//         let totalFat = 0;
//         let totalKcals = 0;
    
//         const ingredients = isMealInstance
//             ? mealData.attributes.custom_ingredients
//             : mealData.attributes.meal_ingredients.data;
    
//         ingredients.forEach(ingredient => {
//             const amount = isMealInstance
//                 ? parseFloat(ingredient.amount)
//                 : parseFloat(ingredient.attributes.amount); // Henter amount fra riktig sted
    
//             const unit = isMealInstance ? ingredient.unit : ingredient.attributes.unit;
//             const macros = isMealInstance
//                 ? ingredient.macros
//                 : ingredient.attributes.ingredients.data[0].attributes.macros[0]; // Makroer for meal
    
//             if (unit === "stk") {
//                 totalProtein += macros.protein * amount;
//                 totalCarbs += macros.carbs * amount;
//                 totalFat += macros.fat * amount;
//                 totalKcals += macros.kcals * amount;
//             } else if (unit === "g") {
//                 totalProtein += (macros.protein / 100) * amount;
//                 totalCarbs += (macros.carbs / 100) * amount;
//                 totalFat += (macros.fat / 100) * amount;
//                 totalKcals += (macros.kcals / 100) * amount;
//             } else if (unit === "dl") {
//                 const gramsEquivalent = amount * 100;
//                 totalProtein += (macros.protein / 100) * gramsEquivalent;
//                 totalCarbs += (macros.carbs / 100) * gramsEquivalent;
//                 totalFat += (macros.fat / 100) * gramsEquivalent;
//                 totalKcals += (macros.kcals / 100) * gramsEquivalent;
//             }
//         });
    
//         return {
//             totalProtein: Math.round(totalProtein),
//             totalCarbs: Math.round(totalCarbs),
//             totalFat: Math.round(totalFat),
//             totalKcals: Math.round(totalKcals),
//         };
//     };
    
//     const { totalProtein, totalCarbs, totalFat, totalKcals } = calculateNutrition();

//     return (
//         <div className={styles.detailContainer}>
//             <div className={styles.header}>
//                 <button className={styles.backButton} onClick={onBack}>Tilbake</button>
//             </div>
//             <div className={styles.name}>{name}</div>
//             <div className={styles.category}>{category}</div>
//             <div className={styles.mealImage} style={{ backgroundImage: `url(${imageUrl})` }} />
//             <div className={styles.prepInfo}>
//                 <div className={styles.prepChild}>
//                     <div className={styles.prepSubHeader}>
//                         <div className={styles.iconContainer}>
//                             <Image src={Clock} fluid alt="clock icon" />
//                         </div>
//                         <div className={styles.prepText}>Prep:</div>
//                     </div>
//                     <p><strong>{prep_time} min</strong></p>
//                 </div>
//                 <div className={styles.prepChild}>
//                     <div className={styles.prepSubHeader}>
//                         <div className={styles.iconContainer}>
//                             <Image src={Chef} fluid alt="chef icon" />
//                         </div>
//                         <div className={styles.prepText}>Cooking:</div>
//                     </div>
//                     <p><strong>{cooking_time} min</strong></p>
//                 </div>
//                 <div className={styles.prepChild}>
//                     <div className={styles.prepSubHeader}>
//                         <div className={styles.iconContainer}>
//                             <Image src={Serving} fluid alt="servings icon" />
//                         </div>
//                         <div className={styles.prepText}>Serving:</div>
//                     </div>
//                     <p><strong>{serving} serving</strong></p>
//                 </div>
//             </div>
//             <div className={styles.nutritionContainer}>
//                 <h3>Næringsinnhold</h3>
//                 <div className={styles.nutriSubHeader}>Per måltid</div>
//                 <div className={styles.nutriChild}>
//                     <div className={styles.nutriSub1}>
//                         <div className={styles.nutriP}>{totalKcals} kcal</div>
//                         <div className={styles.nutriS}>Kalorier</div>
//                     </div>
//                     <div className={styles.nutriSub1}>
//                         <div className={styles.nutriP}>{totalProtein}g</div>
//                         <div className={styles.nutriS}>Protein</div>
//                     </div>
//                     <div className={styles.nutriSub1}>
//                         <div className={styles.nutriP}> {totalCarbs}g</div>
//                         <div className={styles.nutriS}>Karbo</div>
//                     </div>
//                     <div className={styles.nutriSub1}>
//                         <div className={styles.nutriP}>{totalFat}g</div>
//                         <div className={styles.nutriS}>Fett</div>
//                     </div>
//                 </div>
//             </div>
//             <div className={styles.ingredientsContainer}>
//             <h3>Ingredienser</h3>
//                 {(isMealInstance ? mealData.attributes.custom_ingredients : mealData.attributes.meal_ingredients.data).map(ingredient => (
//                     <div className={styles.ingredientChild} key={ingredient.ingredient_name || ingredient.id}>
//                         <div className={styles.ingredientIconContainer}>
//                             <Image fluid alt="nutrients icon" src={IngredientIcon} />
//                         </div>
//                         <div>{ingredient.ingredient_name || ingredient.attributes.ingredients.data[0].attributes.name}</div>
//                         <div>{isMealInstance ? ingredient.amount : ingredient.attributes.amount} {isMealInstance ? ingredient.unit : ingredient.attributes.unit}</div>
//                     </div>
//                 ))}
//             </div>
//             <div className={styles.tabsContainer}>
//                 <div className={styles.customTabs}>
//                     <button className={`${styles.tabButton} ${activeTab === "preparation" ? styles.activeTab : ""}`} onClick={() => setActiveTab("preparation")}>
//                         Prep
//                     </button>
//                     <button className={`${styles.tabButton} ${activeTab === "description" ? styles.activeTab : ""}`} onClick={() => setActiveTab("description")}>
//                         Beskrivelse
//                     </button>
//                     <button className={`${styles.tabButton} ${activeTab === "cookingInstructions" ? styles.activeTab : ""}`} onClick={() => setActiveTab("cookingInstructions")}>
//                         Instruksjoner
//                     </button>
//                 </div>

//                 <div className={styles.tabContent}>
//                     {activeTab === "preparation" && <p>{preparation || "Ingen prep tilgjengelig"}</p>}
//                     {activeTab === "description" && <p>{description || "Ingen beskrivelse tilgjengelig"}</p>}
//                     {activeTab === "cookingInstructions" && <p>{cooking_instructions || "Ingen instruksjoner tilgjengelig"}</p>}
//                 </div>
//             </div>
//         </div>
//     );
// }

import React, { useState } from 'react';
import styles from './styles.module.css';
import Clock from '../../../../images/icons/timer.png';
import Chef from '../../../../images/icons/chef-hat.png';
import Serving from '../../../../images/icons/user (2).png';
import IngredientIcon from '../../../../images/icons/harvest.png';
import Image from 'react-bootstrap/Image';
import { ReactComponent as BackIcon } from '../../../../images/icons/back.svg';

export default function MealDetail({ mealData, onBack }) {
    const mealAttributes = mealData.isMealInstance ? mealData.attributes.meal.data.attributes : mealData.attributes;
    const { name, prep_time, cooking_time, serving, meal_images, category, preparation, description, cooking_instructions } = mealAttributes;
    const imageUrl = meal_images?.data[0]?.attributes?.image?.data?.attributes?.url;
    const [activeTab, setActiveTab] = useState("description");

    const calculateNutrition = () => {
        let totalProtein = 0;
        let totalCarbs = 0;
        let totalFat = 0;
        let totalKcals = 0;

        const ingredients = mealData.isMealInstance 
            ? mealData.attributes.custom_ingredients 
            : mealData.attributes.meal_ingredients.data;

        ingredients.forEach(ingredient => {
            const amount = mealData.isMealInstance 
                ? parseFloat(ingredient.amount) 
                : parseFloat(ingredient.attributes.amount);

            const unit = mealData.isMealInstance ? ingredient.unit : ingredient.attributes.unit;
            const macros = mealData.isMealInstance 
                ? ingredient.macros 
                : ingredient.attributes.ingredients.data[0].attributes.macros[0];

            if (unit === "stk") {
                totalProtein += macros.protein * amount;
                totalCarbs += macros.carbs * amount;
                totalFat += macros.fat * amount;
                totalKcals += macros.kcals * amount;
            } else if (unit === "g") {
                totalProtein += (macros.protein / 100) * amount;
                totalCarbs += (macros.carbs / 100) * amount;
                totalFat += (macros.fat / 100) * amount;
                totalKcals += (macros.kcals / 100) * amount;
            } else if (unit === "dl") {
                const gramsEquivalent = amount * 100;
                totalProtein += (macros.protein / 100) * gramsEquivalent;
                totalCarbs += (macros.carbs / 100) * gramsEquivalent;
                totalFat += (macros.fat / 100) * gramsEquivalent;
                totalKcals += (macros.kcals / 100) * gramsEquivalent;
            }
        });

        return {
            totalProtein: Math.round(totalProtein),
            totalCarbs: Math.round(totalCarbs),
            totalFat: Math.round(totalFat),
            totalKcals: Math.round(totalKcals),
        };
    };

    const { totalProtein, totalCarbs, totalFat, totalKcals } = calculateNutrition();

    return (
        <div className={`${styles.detailContainer} appearing-container`}>
            <div className={styles.header}>
                <BackIcon className={styles.backIcon} />
                <button className={styles.backButton} onClick={onBack}>Tilbake</button>
            </div>
            <div className={styles.name}>{name}</div>
            <div className={styles.category}>{category}</div>
            <div className={styles.mealImage} style={{ backgroundImage: `url(${imageUrl})` }} />
            <div className={styles.prepInfo}>
                <div className={styles.prepChild}>
                    <div className={styles.prepSubHeader}>
                        <div className={styles.iconContainer}>
                            <Image src={Clock} fluid alt="clock icon" />
                        </div>
                        <div className={styles.prepText}>Prep:</div>
                    </div>
                    <p><strong>{prep_time} min</strong></p>
                </div>
                <div className={styles.prepChild}>
                    <div className={styles.prepSubHeader}>
                        <div className={styles.iconContainer}>
                            <Image src={Chef} fluid alt="chef icon" />
                        </div>
                        <div className={styles.prepText}>Cooking:</div>
                    </div>
                    <p><strong>{cooking_time} min</strong></p>
                </div>
                <div className={styles.prepChild}>
                    <div className={styles.prepSubHeader}>
                        <div className={styles.iconContainer}>
                            <Image src={Serving} fluid alt="servings icon" />
                        </div>
                        <div className={styles.prepText}>Serving:</div>
                    </div>
                    <p><strong>{serving} serving</strong></p>
                </div>
            </div>
            <div className={styles.nutritionContainer}>
                <h3>Næringsinnhold</h3>
                <div className={styles.nutriSubHeader}>Per måltid</div>
                <div className={styles.nutriChild}>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{totalKcals} kcal</div>
                        <div className={styles.nutriS}>Kalorier</div>
                    </div>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{totalProtein}g</div>
                        <div className={styles.nutriS}>Protein</div>
                    </div>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{totalCarbs}g</div>
                        <div className={styles.nutriS}>Karbo</div>
                    </div>
                    <div className={styles.nutriSub1}>
                        <div className={styles.nutriP}>{totalFat}g</div>
                        <div className={styles.nutriS}>Fett</div>
                    </div>
                </div>
            </div>
            <div className={styles.ingredientsContainer}>
                <h3>Ingredienser</h3>
                {(mealData.isMealInstance ? mealData.attributes.custom_ingredients : mealData.attributes.meal_ingredients.data).map(ingredient => (
                    <div className={styles.ingredientChild} key={ingredient.ingredient_name || ingredient.id}>
                        <div className={styles.ingredientIconContainer}>
                            <Image fluid alt="nutrients icon" src={IngredientIcon} />
                        </div>
                        <div>{ingredient.ingredient_name || ingredient.attributes.ingredients.data[0].attributes.name}</div>
                        <div>{mealData.isMealInstance ? ingredient.amount : ingredient.attributes.amount} {mealData.isMealInstance ? ingredient.unit : ingredient.attributes.unit}</div>
                    </div>
                ))}
            </div>
            <div className={styles.tabsContainer}>
                <div className={styles.customTabs}>
                    <button className={`${styles.tabButton} ${activeTab === "preparation" ? styles.activeTab : ""}`} onClick={() => setActiveTab("preparation")}>
                        Prep
                    </button>
                    <button className={`${styles.tabButton} ${activeTab === "description" ? styles.activeTab : ""}`} onClick={() => setActiveTab("description")}>
                        Beskrivelse
                    </button>
                    <button className={`${styles.tabButton} ${activeTab === "cookingInstructions" ? styles.activeTab : ""}`} onClick={() => setActiveTab("cookingInstructions")}>
                        Instruksjoner
                    </button>
                </div>

                <div className={styles.tabContent}>
                    {activeTab === "preparation" && <p>{preparation || "Ingen prep tilgjengelig"}</p>}
                    {activeTab === "description" && <p>{description || "Ingen beskrivelse tilgjengelig"}</p>}
                    {activeTab === "cookingInstructions" && <p>{cooking_instructions || "Ingen instruksjoner tilgjengelig"}</p>}
                </div>
            </div>
        </div>
    );
}
