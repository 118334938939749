import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { axiosAuthFetch } from '../../../helpers/axios';
import { API_GRAPHQL } from '../../../constants';
import Image from 'react-bootstrap/Image';
import defaultImage from '../../../images/blue_man.png';
import Modal from 'react-bootstrap/Modal';

export default function CoachCards(){

    const [coaches, setCoaches] = useState([]);
    const [selectedCoach, setSelectedCoach] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleCoachClick = (coach) => {
        setSelectedCoach(coach);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedCoach(null);
    };

    const query = `query {
        coaches {
            data {
                id
                attributes {
                    clients{
                        data{
                            id
                            attributes{
                                username
                            }
                        }
                    }
                    user {
                        data {
                            id
                            attributes {
                                username
                                profile_picture{
                                    data{
                                        id
                                        attributes{
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }    
        }
    }`

    useEffect(() => {
        async function getCoaches() {
            try {
                const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
                console.log('COACHES',response.data.coaches.data);
                setCoaches(response.data.coaches.data);
            } catch(error) {
                console.error(error);
            }
        };

        getCoaches();
        //eslint-disable-next-line
    }, []);

    console.log(coaches);

    return(
        <div className={styles.cardContainer}>
            {coaches.length > 0 ? (
                    coaches.map((coach) => {
                        const { username } = coach?.attributes?.user?.data?.attributes;
                        const imageUrl = coach.attributes?.user?.data?.attributes?.profile_picture?.data?.attributes?.url || defaultImage;

                        return (
                            <div
                                key={coach.id}
                                className={styles.card}
                                onClick={() => handleCoachClick(coach)}
                            >
                                <div className={styles.image}>
                                    <div className={styles.profilePictureContainer}>
                                        <Image className={styles.clientProfilePicture} fluid alt="client-image" src={imageUrl} />
                                    </div>
                                </div>
                                <div className={styles.infoDiv}>
                                    <div className={styles.name}>{username}</div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className={styles.noClients}>
                        Du har ingen klienter.
                    </div>
                )}
            <Modal className={styles.modal} size="lg" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Coachdetaljer</Modal.Title>
                </Modal.Header>
                {selectedCoach && (
                    <Modal.Body>
                        <div className={styles.modalBody}>
                            <div className={styles.modalImageContainer}>
                                <Image className={styles.modalProfilePicture} fluid alt="client-image" src={selectedCoach.attributes?.user?.data?.attributes?.profile_picture?.data?.attributes?.url || defaultImage } />
                            </div>
                            <div className={styles.modalInfo}>
                                <p><strong>Brukernavn:</strong> {selectedCoach.attributes.user.data.attributes.username}</p>
                                <p><strong>Antall klienter:</strong> {selectedCoach.attributes.clients.data.length}</p>
                            </div>
                        </div>
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <div className={styles.closeButton} onClick={handleCloseModal}>
                        Lukk
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}