import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { axiosAuthFetch } from '../../../helpers/axios';
import Loader from '../loader';
import { API_GRAPHQL } from '../../../constants';

export default function Workouts(user) {

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [workouts, setWorkouts] = useState([]);
    const {id} = user.user.user;

    const query = `
        query{usersPermissionsUser(id:${id}){
                data{
                    id
                    attributes{
                        programs{
                                data{
                                    id
                                    attributes{
                                        programName
                                        cover_image{
                                            data{
                                                id
                                                attributes{
                                                    images{
                                                        data{
                                                            id
                                                            attributes{
                                                                url
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                    }
                }
            }
        }
    `;

    useEffect(() => {
        
        async function getWorkouts(){
            try{
                setIsLoading(true);
                setIsError(false);
                const response = await axiosAuthFetch(`${API_GRAPHQL}`,{ query }, 'POST');
                console.log(response);
                setWorkouts(response?.data?.usersPermissionsUser?.data?.attributes?.programs?.data);
            } catch(error){
                console.error(error);
                setIsError(true)
            } finally{
                setIsLoading(false);
            };
        };
        
        getWorkouts();
    },[query]);

    const redirectToTraining = () => {
        window.location="/training";
    }

    if(isLoading) {
        <Loader />
    }
    if(isError) {
        <div>Oida, her gikk noe galt! Prøv å last inn på nytt!</div>
    }
    return(
        <div className={styles.container} onClick={redirectToTraining}>
            <h5>Dine treningsprogram</h5>
            <div className={styles.programsDivInner}>
                {workouts.map((program) => (
                    <div key={program.id} className={styles.programItem} style={{
                        backgroundImage:`url(${program.attributes.cover_image.data.attributes.images.data[0].attributes.url})`
                    }}>
                        {/* <div className={styles.programHeader} style={{
                            backgroundImage:`url(${program.attributes.cover_image.data.attributes.images.data[0].attributes.url})`
                        }}></div> */}
                        <div className={styles.text}>{program.attributes.programName}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}