import React, {useState, useEffect} from 'react';
// import ReactSelect from 'react-select';
import createForm from './cp.module.css';
//import axios from 'axios';
import { API_BASE_URL, API_GRAPHQL } from '../../../../constants';
//import useGetUsers from '../../../../helpers/users';
import Loader from '../../loader';
import { axiosAuthFetch } from '../../../../helpers/axios';
import {SearchableUserDropdown, SearchableExercisesDropdown} from '../../searchableDropdown';
import { getLocalStorageItem } from '../../storage';
import { v4 as uuidv4 } from 'uuid';
import CustomAlert from '../../customAlert';

export default function CreateProgramForm({ options }) {

    // const [selectedExercises, setSelectedExercises] = useState([{ id: 1, value: null, writtenInput: '' }]);
    const [programName, setProgramName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [users, setUsers] = useState([]);
    const [UserID, setUserID] = useState(null);
    const coachUserInfo = getLocalStorageItem('limitless-user');
    const coachID = coachUserInfo.user.coachID;
    const [pairs, setPairs] = useState([{id: uuidv4(), description: '', exercise: '', comment: ''}]);
    const [showAlert, setShowAlert] = useState(false);
    const [coverPhotos, setCoverPhotos] = useState([]);

    const query = ` 
                query{
                    coaches(filters: {id:{eq:${coachID}}}) {
                    data{
                        id
                        attributes{
                        clients{
                            data{
                            id
                            attributes{
                                username
                            }
                            }
                        }
                        }
                    }
                    }
                }
            `;

    useEffect(() => {
        async function getUsers() {
            setIsLoading(true);
            setIsError(false);
            try {
                const response = await axiosAuthFetch(`${API_GRAPHQL}`, {query}, 'POST');
                console.log(response.data.coaches.data[0].attributes.clients.data);
                setUsers(response.data.coaches.data[0].attributes.clients.data);
                // console.log(users);
            } catch(error) {
                console.error(error)
                setIsError(true)
            } finally {
                setIsLoading(false);
            }
        }
        getUsers();
        //eslint-disable-next-line
    }, [query]);

    // const query2 = `
    //     query{
    //         coverImagesPlural{
    //         data{
    //             id
    //             attributes{
    //             images{
    //                 data{
    //                 id
    //                 attributes{
    //                     url
    //                 }
    //                 }
    //             }
    //             }
    //         }
    //         }
    //     }`;

    useEffect(() => {
        async function getCoverPhotos() {
            try {
                const response = await axiosAuthFetch(`${API_BASE_URL}/cover-images-plural`);
                setCoverPhotos(response.data);
            } catch(error) {
                console.error(error);
            }
        }
        getCoverPhotos();
        //eslint-disable-next-line
    }, []);

    if(isLoading) {
        return <> <Loader /> </>
    } if(isError) {
        return (
            <div>Error</div>
        )
    }

    const getRandomCoverPhoto = () => {
        if (coverPhotos.length > 0) {
            const randomIndex = Math.floor(Math.random() * coverPhotos.length);
            return coverPhotos[randomIndex];
        }
        return null;
    };
    

    const handleExerciseChange = (pairId, selectedOption) => {
        setPairs(prevPairs =>
            prevPairs.map(pair =>
                pair.id === pairId ? {...pair, exercise: selectedOption } : pair)
        );
    };

    const handleDescriptionChange = (pairId, event) => {
        setPairs(prevPairs =>
            prevPairs.map(pair =>
                pair.id === pairId ? {...pair, description: event.target.value} : pair));
    };

    const handleCommentChange = (pairId, event) => {
        setPairs(prevPairs =>
            prevPairs.map(pair =>
                pair.id === pairId ? { ...pair, comment: event.target.value } : pair
            )
        );
    };

    const handleUserChange = selectedOption => {
        if (selectedOption) {
            setUserID(selectedOption.id);
        } else {
            setUserID(''); // Eller en tom verdi som gir mening for din app
        }
    };

    const handleAddPair = () => {
        setPairs([...pairs, {id: uuidv4(), description: '', exercise: '' , comment: ''}]);
    };

    const handleRemovePair = (pairId) => {
        setPairs(prevPairs => prevPairs.filter(pair => pair.id !== pairId))
    };

    


    const createPair = async (exercise, description, comment) => {
        try {
            const response = await axiosAuthFetch(`${API_BASE_URL}/pairs`, {
                data: {
                    exercise: exercise.id,
                    description: description,
                    comment: comment,
                }
            }, 'POST');
            return response.data.id;
        } catch(error) {
            console.error(error);
        }
    };

    const handleCreateProgram = async () => {
        if (!programName.trim()) {
        alert("Vennligst angi et navn på programmet.");
        return;
    }
    if (pairs.length === 0 || !pairs[0].exercise) {
        alert("Vennligst legg til minst én øvelse før du oppretter programmet.");
        return;
    }
        setIsLoading(true);
        try{
            const pairIDs = [];

            for (const pair of pairs) {
                try {
                    const pairID = await createPair(pair.exercise, pair.description, pair.comment);
                    pairIDs.push(pairID);
                } catch(error) {
                    console.error(error)
                }
            }

            const randomCoverPhoto = getRandomCoverPhoto();

            const programData = {
                data: {
                    programName: programName,
                    users: UserID,
                    created_by_coach: [coachID],
                    pairs: pairIDs,
                    cover_image: randomCoverPhoto ? randomCoverPhoto.id : null,
                }
            };
    
            const creationResponse = await axiosAuthFetch(`${API_BASE_URL}/programs`, programData, 'POST');
            console.log(creationResponse);
            setIsLoading(false);
            setShowAlert(true);
        } catch(error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const handleAlertClose = () => {
        setShowAlert(false);
        window.location.reload();
    }

    return (
        <div className={createForm.container1}>
            <CustomAlert 
                show={showAlert}
                handleClose={handleAlertClose}
                buttonLabel="Den er grei!"
                content="Programmet er opprettet!"
                title="Suksess!"
            />
            <input 
                className={createForm.writtenInputHeader}
                placeholder='Navn på program'
                value = {programName}
                onChange={(event) => setProgramName(event.target.value)}
                required
            />
            <div className={createForm.userText}>Velg klient</div>
            <SearchableUserDropdown 
                objects={users}
                onSelect={handleUserChange}
            />
            <div className={createForm.smallText}>(Hvis du ikke velger en klient blir programmet lagret som en mal og du finner det igjen
                under dine program, hvor du senere kan tildele det til én eller flere klienter)
            </div>
            <div className={createForm.userText}>Velg øvelser</div>
            <div className={createForm.cardContainer}>
                {pairs.map((pair, index) => (
                    <div className={createForm.container2} key={pair.id}>
                        <div className={createForm.underoverskrift}> Øvelse {index + 1}</div>
                        <SearchableExercisesDropdown 
                            objects={options}
                            onSelect={(selectedOption) => handleExerciseChange(pair.id, selectedOption)}
                            selectedValue={pair.exercise}
                            id={`exercise-dropdown-${index}`}
                        />
                        <input
                            className={createForm.writtenInput}
                            placeholder="Sett"
                            value={pair.description}
                            onChange={(event) => handleDescriptionChange(pair.id, event)}
                        />
                        <input
                            className={createForm.writtenInput}
                            placeholder="Reps"
                            value={pair.comment}
                            onChange={(event) => handleCommentChange(pair.id, event)}
                        />
                        {index !== 0 && (
                            <button className={`${createForm.button} ${createForm.removeButton}`} onClick={() => handleRemovePair(pair.id)}>Fjern øvelse</button>
                        )}
                    </div>
                ))}
            </div>
            <button className={createForm.button} onClick={handleAddPair}>Legg til øvelse</button>
            <button className={`${createForm.button} ${createForm.createButton}`} onClick={handleCreateProgram}>Opprett program</button>
        </div>
    );
}




// const handleAddExercise = () => {
    //     setSelectedExercises([...selectedExercises, { id: Date.now(), value: null, writtenInput: '' }]);
    // };

    // const handleExerciseChange = (index, selectedOption) => {
    //     const updatedExercises = [...selectedExercises];
    //     updatedExercises[index] = selectedOption;
    //     setSelectedExercises(updatedExercises);
        
    //     console.log('updated exercises',updatedExercises)
    // };

    // const handleRemoveExercise = (index) => {
    //     const updatedExercises = [...selectedExercises];
    //     updatedExercises.splice(index, 1);
    //     setSelectedExercises(updatedExercises);
    // };

    // const handleWrittenInputChange = (index, event) => {
    //     const updatedExercises = [...selectedExercises];
    //     updatedExercises[index].writtenInput = event.target.value;
    //     setSelectedExercises(updatedExercises);
    // }

    // const handleAddExercise = (pairId, exercise) => {
    //     setPairs(prevPairs => {
    //         const updatedPairs = prevPairs.map(pair =>
    //             pair.id === pairId ? { ...pair, exercise } : pair
    //         );
    //         return updatedPairs;
    //     });
    // };

    // const handlePairDescriptionChange = (id, description) => {
    //     setPairs(prevPairs => {
    //         const updatedPairs = prevPairs.map(pair =>
    //             pair.id === id ? { ...pair, description } : pair
    //         );
    //         return updatedPairs;
    //     });
    // };