import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import ClientCard from '../clientCard';
import { axiosAuthFetch } from '../../../../../helpers/axios';
import { API_GRAPHQL, API_BASE_URL } from '../../../../../constants';
import { getLocalStorageItem } from '../../../../global/storage';
import Loader from '../../../../global/loader';

export default function ClientsList({ mealId , allIngredients }) {
    const [clientsData, setClientsData] = useState([]);
    //eslint-disable-next-line
    const [mealIngredients, setMealIngredients] = useState([]);
    const userInfo = getLocalStorageItem('limitless-user');
    const coachID = userInfo.user.coachID;
    //eslint-disable-next-line
    const [allClients, setAllClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [filteredClients, setFilteredClients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const query = `
        query {
            meal(id:${mealId}) {
                    data{
                    id
                    attributes{
                        meal_ingredients{
                            data{
                                id
                                attributes{
                                    amount
                                    unit
                                    ingredients{
                                        data{
                                            id
                                            attributes{
                                                name
                                                macros{
                                                    fat
                                                    protein
                                                    carbs
                                                    kcals
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        meal_instances{
                            data{
                                id
                                attributes{
                                    custom_ingredients
                                    variant_name
                                    clients{
                                        data{
                                            id
                                            attributes{
                                                username
                                                profile_picture{
                                                    data{
                                                        id
                                                        attributes{
                                                            url
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        clients{
                        data{
                            id
                            attributes{
                            username
                            profile_picture{
                                data{
                                id
                                attributes{
                                    url
                                }
                                }
                            }
                            }
                        }
                        }
                    }
                    }
                }
                coaches(filters: {id: {eq: ${coachID}}}) {
                    data{
                        attributes{
                            clients{
                                data{
                                    id
                                    attributes{
                                        username
                                    }
                                }
                            }
                        }
                    }
                }
        }
    `;

    // useEffect(() => {
    //     const getClients = async () => {
    //         const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
    //         console.log(response);

    //         const mealData = response.data.meal.data;
    //         const clientsList = [];
            
    //         // Henter hovedmåltidets ingredienser
    //         const ingredients = mealData.attributes.meal_ingredients.data.map(ingredient => ({
    //             id: ingredient.id,
    //             name: ingredient.attributes.ingredients.data[0].attributes.name,
    //             amount: ingredient.attributes.amount,
    //             unit: ingredient.attributes.unit,
    //             macros: ingredient.attributes.ingredients.data[0].attributes.macros[0]
    //         }));
    //         setMealIngredients(ingredients);

    //         // Legger til klienter for hovedmåltidet
    //         mealData.attributes.clients.data.forEach(client => {
    //             clientsList.push({
    //                 id: client.id,
    //                 username: client.attributes.username,
    //                 profilePicture: client.attributes.profile_picture?.data?.attributes?.url,
    //                 variantName: 'Hovedmåltid'
    //             });
    //         });

    //         // Legger til klienter for hver variant
    //         mealData.attributes.meal_instances.data.forEach(instance => {
    //             instance.attributes.clients.data.forEach(client => {
    //                 clientsList.push({
    //                     id: client.id,
    //                     username: client.attributes.username,
    //                     profilePicture: client.attributes.profile_picture?.data?.attributes?.url,
    //                     variantName: instance.attributes.variant_name
    //                 });
    //             });
    //         });

    //         setClientsData(clientsList);
    //     };
    //     getClients();
    //     //eslint-disable-next-line
    // }, [mealId]);

    useEffect(() => {
        const getClients = async () => {
          const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
          console.log('API Response:', response);
      
          const mealData = response.data.meal.data;
          const clientsList = [];
      
          // Fetch main meal ingredients
          const ingredients = mealData.attributes.meal_ingredients.data.map(ingredient => ({
            id: ingredient.id,
            name: ingredient.attributes.ingredients.data[0].attributes.name,
            amount: ingredient.attributes.amount,
            unit: ingredient.attributes.unit,
            macros: ingredient.attributes.ingredients.data[0].attributes.macros[0],
            ingredientId: ingredient.attributes.ingredients.data[0].id,
          }));
          setMealIngredients(ingredients);
      
          // Add clients from the main meal
          mealData.attributes.clients.data.forEach(client => {
            clientsList.push({
              id: client.id,
              username: client.attributes.username,
              profilePicture: client.attributes.profile_picture?.data?.attributes?.url,
              variantName: 'Hovedmåltid',
              ingredients: ingredients, // Use main meal ingredients
              isVariant: false, // Indicates main meal
              mealInstanceId: null,
            });
          });
      
          // Add clients from meal instances (variants)
          mealData.attributes.meal_instances.data.forEach(instance => {
            const customIngredientsArray = instance.attributes.custom_ingredients;
            let instanceIngredients = [];
      
            if (Array.isArray(customIngredientsArray)) {
              instanceIngredients = customIngredientsArray.map(ci => ({
                id: null, // No ID for custom ingredients
                name: ci.ingredient_name,
                amount: ci.amount,
                unit: ci.unit,
                macros: ci.macros,
                ingredientId: ci.ingredients ? ci.ingredients[0] : null, // Assuming `ingredients` is an array of IDs
              }));
            } else {
              console.warn('custom_ingredients is not an array:', customIngredientsArray);
            }
      
            instance.attributes.clients.data.forEach(client => {
              clientsList.push({
                id: client.id,
                username: client.attributes.username,
                profilePicture: client.attributes.profile_picture?.data?.attributes?.url,
                variantName: instance.attributes.variant_name,
                ingredients: instanceIngredients,
                isVariant: true, // Indicates variant
                mealInstanceId: instance.id, // Store the meal instance ID
              });
            });
          });
      
          setClientsData(clientsList);

          const coachClients = response.data.coaches.data[0].attributes.clients.data.map(client => ({
            id: client.id,
            username: client.attributes.username,
        }));
        setAllClients(coachClients);

        const assignedClientIds = new Set(clientsList.map(client => client.id));
            const unassignedClients = coachClients.filter(client => !assignedClientIds.has(client.id));
            setFilteredClients(unassignedClients);
        };
      
        getClients();
        // eslint-disable-next-line
      }, [mealId]);

      const handleAddClientToMeal = async () => {
        if (!selectedClient) return;

        try {
            setIsLoading(true);
            await axiosAuthFetch(`${API_BASE_URL}/meals/${mealId}`, {
                data: {
                    clients: {
                        connect: [selectedClient.id]
                    }
                }
            }, 'PUT');
            
            alert('Klient lagt til i måltidet!');
            setIsLoading(false);
            window.location.reload();
        } catch (error) {
            console.error('Error adding client to meal:', error);
            alert('Det oppstod en feil ved å legge til klienten.');
            setIsLoading(false);
        }
    };

      const deleteVariant = async ({ mealInstanceId, clientId, mealId }) => {
        try {
            setIsLoading(true);
          // Slett meal instance (varianten)
          await axiosAuthFetch(`${API_BASE_URL}/meal-instances/${mealInstanceId}`, {}, 'DELETE');
      
          // Legg klienten tilbake til hovedmåltidet
          await axiosAuthFetch(`${API_BASE_URL}/meals/${mealId}`, {
            data: {
              clients: {
                connect: [clientId]
              }
            }
          }, 'PUT');
      
          alert('Varianten er slettet, og klienten er satt tilbake på hovedmåltidet.');
            setIsLoading(false);
          // Oppdater listen over klienter
          window.location.reload();
        } catch (error) {
          console.error('Error deleting variant:', error);
          alert('Det oppstod en feil ved sletting av varianten.');
          setIsLoading(false);
        }
      };

      const createVariant = async ({ variantName, mealId, ingredients, clientId, mealInstanceId, createNew = false }) => {
        try {
            setIsLoading(true);
    
            // Sjekk om klienten har en eksisterende variant for dette spesifikke måltidet
            if (!mealInstanceId) {
                // SCENARIO 1: Ingen eksisterende variant, opprett en ny variant for klienten
                const newVariantData = {
                    variant_name: variantName,
                    coach: [coachID],
                    meal: mealId,
                    clients: [clientId],
                    custom_ingredients: ingredients.map(ingredient => ({
                        amount: ingredient.amount,
                        unit: ingredient.unit,
                        ingredients: ingredient.ingredientId ? [ingredient.ingredientId] : [],
                        ingredient_name: ingredient.name,
                        macros: {
                            protein: ingredient.macros?.protein || 0,
                            fat: ingredient.macros?.fat || 0,
                            carbs: ingredient.macros?.carbs || 0,
                            kcals: ingredient.macros?.kcals || 0,
                        }
                    })),
                };
    
                await axiosAuthFetch(`${API_BASE_URL}/meal-instances`, { data: newVariantData }, 'POST');
    
                // Fjern klienten fra hovedmåltidet
                await axiosAuthFetch(`${API_BASE_URL}/meals/${mealId}`, {
                    data: { clients: { disconnect: [clientId] } }
                }, 'PUT');
    
                alert('Ny variant opprettet!');
            } else {
                // Sjekk om vi skal oppdatere eksisterende variant eller opprette ny
                const response = await axiosAuthFetch(`${API_BASE_URL}/meal-instances/${mealInstanceId}`);
                console.log(response.data);
                const existingInstance = response.data;
    
                if (existingInstance && !createNew) {
                    // SCENARIO 2a: Oppdater eksisterende variant uten å endre klienttilkoblinger
                    const variantData = {
                        variant_name: variantName,
                        coach: [coachID],
                        meal: mealId,
                        custom_ingredients: ingredients.map(ingredient => ({
                            amount: ingredient.amount,
                            unit: ingredient.unit,
                            ingredients: ingredient.ingredientId ? [ingredient.ingredientId] : [],
                            ingredient_name: ingredient.name,
                            macros: {
                                protein: ingredient.macros?.protein || 0,
                                fat: ingredient.macros?.fat || 0,
                                carbs: ingredient.macros?.carbs || 0,
                                kcals: ingredient.macros?.kcals || 0,
                            }
                        })),
                    };
    
                    await axiosAuthFetch(`${API_BASE_URL}/meal-instances/${mealInstanceId}`, { data: variantData }, 'PUT');
                    alert('Varianten er oppdatert!');
                } else {
                    // SCENARIO 2b: Koble fra klienten fra eksisterende variant og opprett en ny variant
                    await axiosAuthFetch(`${API_BASE_URL}/meal-instances/${mealInstanceId}`, {
                        data: { clients: { disconnect: [clientId] } }
                    }, 'PUT');
    
                    const newVariantData = {
                        variant_name: variantName,
                        coach: [coachID],
                        meal: mealId,
                        clients: [clientId],
                        custom_ingredients: ingredients.map(ingredient => ({
                            amount: ingredient.amount,
                            unit: ingredient.unit,
                            ingredients: ingredient.ingredientId ? [ingredient.ingredientId] : [],
                            ingredient_name: ingredient.name,
                            macros: {
                                protein: ingredient.macros?.protein || 0,
                                fat: ingredient.macros?.fat || 0,
                                carbs: ingredient.macros?.carbs || 0,
                                kcals: ingredient.macros?.kcals || 0,
                            }
                        })),
                    };
    
                    await axiosAuthFetch(`${API_BASE_URL}/meal-instances`, { data: newVariantData }, 'POST');
                    alert('Ny variant opprettet!');
                }
            }
    
            setIsLoading(false);
    
            // Oppdater listen over klienter etter endring
            window.location.reload();
    
        } catch (error) {
            console.error('Error creating or updating meal variant:', error);
            alert('Det oppstod en feil ved oppretting eller oppdatering av varianten.');
            setIsLoading(false);
        }
    };
    
    if(isLoading) {
        return <Loader />
    }


    return (
        <div className={styles.container}>
            <h3>Klienter som har dette måltidet</h3>
            <div className={styles.addClientContainer}>
                <select
                    value={selectedClient ? selectedClient.id : ''}
                    onChange={(e) => {
                        const client = filteredClients.find(client => client.id === e.target.value);
                        setSelectedClient(client);
                    }}
                    className={styles.select}
                >
                    <option value="">Velg en klient</option>
                    {filteredClients.map(client => (
                        <option key={client.id} value={client.id}>
                            {client.username}
                        </option>
                    ))}
                </select>
                <button className={styles.addClientButton} onClick={handleAddClientToMeal}>Legg til klient</button>
            </div>
            <div className={styles.clientList}>
                {clientsData.map(client => (
                    // <ClientCard key={client.id} mealID={mealId} client={client} ingredients={mealIngredients} allIngredients={allIngredients} createVariant={createVariant}/>
                    <ClientCard
                        key={`${client.id}-${client.variantName}`}
                        mealId={mealId} // Endret fra `mealID` til `mealId`
                        client={client}
                        ingredients={client.ingredients}
                        allIngredients={allIngredients}
                        createVariant={createVariant}
                        deleteVariant={deleteVariant}
                    />
                ))}
            </div>
        </div>
    );
}

