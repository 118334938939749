import React, {useState, useEffect} from 'react';
import styles from './styles.module.css';
import CreateMealForm from '../../../global/forms/createMeal';
import CreateIngredient from '../../../global/forms/createIngredient';
//import MealCard from '../../../global/mealCards/coachSide';
import { getLocalStorageItem } from '../../../global/storage';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_GRAPHQL } from '../../../../constants';
import CoachMealDetail from '../detail/coachSide';
import CoachSideMealCard from '../cards/coachSide';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../../transitions.css';


export default function MealPageLayoutCoachSide() {

    const [meals, setMeals] = useState([]);
    const [clients, setClients] = useState([]);
    const userInfo = getLocalStorageItem('limitless-user');
    const coachId = userInfo.user.coachID;
    const [selectedMeal, setSelectedMeal] = useState(null);
    const [ingredients, setIngredients] = useState([]);
    const [isNavigatingForward, setIsNavigatingForward] = useState(true);
  
    // Henter måltider for coach
    useEffect(() => {
      const fetchMeals = async () => {
        const query = `query {
          meals(filters: { coaches: { id: { eq: ${coachId} } } }) {
            data {
              id
              attributes {
                name
                description
                prep_time
                cooking_time
                serving
                cooking_instructions
                preparation
                category
                meal_instances{
                  data{
                    id
                    attributes{
                      custom_ingredients
                      variant_name
                      clients{
                        data{
                          id
                          attributes{
                            username
                            profile_picture{
                              data{
                                id
                                attributes{
                                  url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                meal_ingredients {
                  data {
                    id
                    attributes {
                      unit
                      amount
                      ingredients {
                        data {
                          id
                          attributes {
                            name
                            macros {
                              protein
                              fat
                              carbs
                              kcals
                            }
                          }
                        }
                      }
                    }
                  }
                }
                meal_images {
                  data {
                    attributes {
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                clients {
                  data {
                    id
                    attributes {
                      username
                    }
                  }
                }
              }
            }
          }
        }`;
        try {
          const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'POST');
          setMeals(response.data?.meals?.data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchMeals();
    }, [coachId]);

    useEffect(() => {
        const fetchIngredients = async () => {
            const query = `query {
                ingredients(pagination: {limit: 1000}) {
                    data {
                        id
                        attributes {
                            name
                            macros {
                                protein
                                fat
                                carbs
                                kcals
                            }
                        }
                    }
                }
            }`;
            try {
                const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'POST');
                console.log(response);
                setIngredients(response.data.ingredients.data);
            } catch (error) {
                console.error("Error fetching ingredients:", error);
            }
        };
        fetchIngredients();
    }, []);
  
    // Henter alle klientene til coachen
    useEffect(() => {
      const fetchClients = async () => {
        const query = `query {
          coaches(filters: { id: { eq: ${coachId} } }) {
            data {
              id
              attributes {
                clients {
                  data {
                    id
                    attributes {
                      username
                    }
                  }
                }
              }
            }
          }
        }`;
  
        try {
          const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'POST');
          // console.log(response.data.coaches.data[0].attributes.clients.data);
          // setClients(response.data.coaches.data[0].attributes.clients.data);
          const clientsData = response.data.coaches.data[0].attributes.clients.data;

          const userInfo = getLocalStorageItem('limitless-user');
          const userID = userInfo.user.id;

          // Filter out the current user from clients list
          const filteredClients = clientsData.filter(client => client.id !== userID);

          setClients(filteredClients);
        } catch (error) {
          console.error("Error fetching clients:", error);
        }
      };
      fetchClients();
    }, [coachId]);

    const handleMealClick = (meal) => {
        setIsNavigatingForward(true); 
        setSelectedMeal(meal);
    };
    

    // Funksjon for å gå tilbake til listen
    const handleBack = () => {
        setIsNavigatingForward(false); // Navigerer bakover
        setSelectedMeal(null);
    };
    

    return (
        <div className={`${styles.container} appearing-container`}>
          <TransitionGroup component={null}>
            <CSSTransition
              key={selectedMeal ? selectedMeal.id : 'list'}
              classNames={isNavigatingForward ? 'slide-forward' : 'slide-backward'}
              timeout={250}
            >
              {selectedMeal ? (
                <CoachMealDetail mealData={selectedMeal} clients={clients} allIngredients={ingredients} onBack={handleBack} />
              ) : (
                <>
                  <div className={styles.child1}>
                    <h2 className="text-center">Dine måltider</h2>
                    <div className={styles.cardContainer}>
                      {meals.map((meal) => (
                        <CoachSideMealCard 
                          key={meal.id} 
                          meal={meal}
                          clients={clients} 
                          coachId={coachId} 
                          onClick={() => handleMealClick(meal)}
                        />
                      ))}
                    </div>
                  </div>
                  <div className={styles.child1}>
                    <CreateIngredient />
                  </div>
                  <div className={styles.child2}>
                    <CreateMealForm />
                  </div>
                </>
              )}
            </CSSTransition>
          </TransitionGroup>
        </div>
      );
}